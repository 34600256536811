
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
    z-index: -1;

    + label {
        cursor: pointer;

        * {
            pointer-events: none;
        }

        // span {
        //     position: relative;

        //     &:before {
        //         position: absolute;
        //         left: -24px;
        //         display: block;
        //         width: 20px;
        //         height: 20px;
        //         content: '';
        //         background: url(/images/icons/paperclip.svg) no-repeat center;
        //         background-size: 20px auto;
        //     }
        // }
    }

}
