/*==========  COLORS  ==========*/
// Color helper classes are next to color definitions in _utils.scss
.color-main {color: $colorMain;}

@each $colorName, $color in $colors {
    .color-#{$colorName} {
        color: $color;
    }
}

/*==========  GENERIC  ==========*/
.text-center {text-align: center;}
.text-left {text-align: left;}
.text-right {text-align: right;}
.text-upper {text-transform: uppercase;}

.right {float: right;}
.left {float: left;}
.nowrap {white-space: nowrap;}
.single-word {word-spacing: 9999px; }
.underline {text-decoration: underline; }
.info {
    margin: 12px 0 32px 0;
    font: {
        size: 14px;
        weight: 300;
    }
}
.relative {position: relative;}

.margin-half-source {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.product-logo {max-width: 240px;}
.hiddenlabel {
    position: absolute;
    z-index: -99;
}
.radius-60 {
    border-radius: 60px;

    @media all and (max-width: 991px) {
        border-radius: 30px;
    }
}

.font-tiny {font-size: $FontSizeTiny}
.font-small {font-size: $FontSizeSmall;}
.font-large {font-size: $FontSizeLarge;}

$gapSizes: (0: 0, 2: 0.2, 5: 0.5, 10: 1, 15: 1.5, 20: 2, 25: 2.5, 30: 3, 35: 3.5, 40: 4, 50: 5, 60: 6);

@each $var, $size in $gapSizes {
    $value: #{$size}em;

    /*==========  MARGINS  ==========*/
    .mt-#{$var}em {margin-top: $value}
    .mb-#{$var}em {margin-bottom: $value}
    .ml-#{$var}em {margin-left: $value}
    .mr-#{$var}em {margin-right: $value}

    /*==========  PADDING  ==========*/
    .pt-#{$var}em {padding-top: $value}
    .pb-#{$var}em {padding-bottom: $value}
    .pl-#{$var}em {padding-left: $value}
    .pr-#{$var}em {padding-right: $value}

    .pall-#{$var}em {padding: $value}

    /*==========  MARGINS  ==========*/
    .mt-tablet-#{$var}em {
        @media all and (max-width: 991px) {
            margin-top: $value
        }
    }
    .mb-tablet-#{$var}em {
        @media all and (max-width: 991px) {
            margin-bottom: $value
        }
    }
    .ml-tablet-#{$var}em {
        @media all and (max-width: 991px) {
            margin-left: $value
        }
    }
    .mr-tablet-#{$var}em {
        @media all and (max-width: 991px) {
            margin-right: $value
        }
    }
}

/*========= RWD HELPERS =========*/
@media screen and (min-width: 200px) and (max-width: 568px) {
    .hide-on-mobile {
        display: none;
    }
}

@media screen and (min-width: 569px) and (max-width: 767px) {
    .hide-on-sm,
    .hide-on-sm-down {
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .hide-on-md,
    .hide-on-md-down {
        display: none;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .hide-on-lg,
    .hide-on-lg-down {
        display: none;
    }
}

@media screen and (max-width: 991px) {
    .hide-on-tablet {
        display: none;
    }
}

@media screen and (min-width: 992px) {
    .hide-on-desktop {
        display: none;
    }
}

/*========= HIDING =========*/

/*
 * Hide visually and from screen readers:
 */
.hidden {display: none !important;}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */
.invisible {visibility: hidden;}

/*
 * Stop scrolling (useful when the mobile menu is opened and you want to prevent scrolling on the scene behind it - in <body>)
 */
.stop-scrolling {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
}

.text-bold {
    font-weight: bold;
}

.text-center-tablet-sm {

    @media all and (min-width: 768px) {
        text-align: center;
    }
 }

 .text-center-mobile {

    @media all and (max-width: 767px) {
        text-align: center;
    }
 }

 .address {
     font-style: normal;
 }

 .full-height {
     height: 100%;
 }


.grecaptcha-badge {
    display: none !important;
}

.font-size {
    &-lg {
        font-size: 24px;
    }
}
