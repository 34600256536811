/**
*
* Button
*
**/

.btn {
    @include inline-block();
    position: relative;
    z-index: 1;
    padding: 12px 24px;
    background-color: transparent;
    border-radius: 50px;
    font: {
        size: 20px;
        weight: 700;
    };
    line-height: 1;
    overflow: hidden;
    transition: color 0.4s ease;

    @media all and (max-width: 574px) {
        font-size: 16px;
    }

    &:focus {
        outline: 0;
    }

    &:not(.btn--not-link) {
        cursor: pointer;
    }

    &__sup {
        margin-right: -5px;
        padding-left: 5px;
    }

    &--small {
        padding: 8px 18px;
        font-size: 15px;
    }

    &--big {

        @media all and (min-width: 575px) {
            font-size: 24px;
        }
    }

    &--large {
        @media all and (min-width: 575px) {
            padding: 18px 48px;
            font-size: 36px;
        }
    }

    @each $colorName, $color in $colors {
        &--#{$colorName} {
            // border: 2px solid $color;
            box-shadow: 0 0 0px 2px $color inset;
            color: $colorSlate;

            &:before,
            &:after {
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                border-radius: 50px;
                background-color: $color;
                will-change: transform;
                transition: transform 0.4s ease;
            }

            &:before {
                transform: translateX(0%);
            }

            &:after {
                transition: transform 0s 0.4s ease;
            }

            @if map-has-key($bgColorsWhiteFont, $colorName) {
                color: #fff;
            }

            &:not(.btn--not-link):hover {
                // color: $color;

                @if map-has-key($bgColorsWhiteFont, $colorName) {
                    color: $color;
                }

                &:before {
                    transform: translateX(-105%);
                    transition: transform 0s ease;
                }

                &:after {
                    transform: translateX(105%);
                    transition: transform 0.4s ease;
                }
            }
        }
    }

    &--transparent {
        background-color: transparent;
        color: $colorSlate;
        text-decoration: underline;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.8;
        }
    }

    &--full {
        width: 100%;
        text-align: center;
    }

    &-refinement {
        margin: {
            top: 48px;
            bottom: 60px;
        }

        @media all and (max-width: 640px) {
            margin: {
                top: 30px;
                bottom: 30px;
            }
        }

        &__item {
            font-size: 20px;
            padding-right: 14px;

            &:hover {
                background-color: $colorOffWhite;
            }

            span {

                position: absolute;
                top: 5px;
                right: 10px;
                font-size: 12px;
                display: inline-block;
                pointer-events: none;
                text-align: center;
                min-width: 24px;
            }

            sup {
                top: -1.05em;
                padding-left: 4px;
                font-size: 12px;
            }
        }
    }

    &-back {
        display: flex;
        align-items: center;
        color: inherit;
        font-weight: 700;
        z-index: 1;
        transition: opacity 0.3s;

        svg {
            margin-right: 8px;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    &-icon {
        position: relative;
        width: 100px;
        height: 100px;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        transition: opacity 0.5s ease-in;

        @media all and (max-width: 640px) {
            width: 48px;
            height: 48px;
        }

        &:hover {

            svg {
                opacity: 0.8;
            }
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            fill: $colorPink;
            transition: opacity 0.3s ease;
        }

        &.active {
            animation-name: rotateIn;
            animation-duration: 0.5s;
            animation-fill-mode: both;
        }

        &-hidden {
            opacity: 0;
            transform: translate3d(0, 0, 0) scale(0);
        }
    }

    &--rectangle {
        border-radius: 0;

        &:before,
        &:after {
            border-radius: 0;
        }
    }
}
