
.fill {
    @each $colorName, $color in $colors {
        &-#{$colorName} {
            fill: $color;
        }
    }
}

.stroke {
    @each $colorName, $color in $colors {
        &-#{$colorName} {
            stroke: $color;
        }
    }
}