.table-custom {
    border-radius: 20px;
    padding: 0 0 30px 0;

    &__headline {
        padding: 20px 1.5em;
    }

    &__wrapper {
        width: 100%;
        overflow-x: auto;
    }

    table {
        width: 100%;
        border-spacing: 0;
        table-layout: fixed;
    }

    td,
    th {
        padding: 1em 1.5em;
        color: $colorMain;
    }

    thead {
        th {
            color: #fff;
            text-align: left;
            border-top: 1px solid #fff;
        }
    }

    tbody tr {
        background-color: $colorWhite;

        &:nth-child(2n+1) {
            background-color: $colorOffWhite;
        }
    }

    &__2-columns {
        td:first-child {
            width: 80%;
        }

        td:last-child {
            width: 20%;
            text-align: right;
        }
    }
}