.mobile-sticky-cta {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;

    background-color: $colorWhite;

    @media (min-width: 992px) {
        display: none;
    }

    .btn {
        padding-top: 1em;
        padding-bottom: 1em;
    }
}