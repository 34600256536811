.circle {
    position: relative;
    margin: 0 auto 36px;
    width: 114px;
    height: 114px;

    @media all and (max-width: 640px) {
        margin-bottom: 18px;
        width: 80px;
        height: 80px;
    }

    svg {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        font-size: 36px;
        font-weight: bold;

        @media all and (max-width: 640px) {
            font-size: 28px;
        }
    }
}