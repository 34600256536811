/**
*
* Blockquote
*
**/

.blockquote {
    position: relative;
    margin: 1em 0;

    > p {
        @include dynamicFontSize(28, 48, 1200, 1600);
        margin: 0;
        position: relative;

       &:nth-of-type(1):before {
            content: '\201C';
        }

        &:last-of-type:after {
            content: '\201D';
        }
    }

    &__author-name {
        @include dynamicFontSize(24, 36, 1200, 1600);
        margin: 15px 0 0;
    }

    &--small {
        padding: 8.333333%;
        border-radius: 60px;
    
        @media all and (max-width: 991px) {
            border-radius: 30px;
        }

        p {
            @media all and (min-width: 991px) {
                width: 85%;
            }
        }
    }

    &--xsmall {
        padding: 6% 5%;
        border-radius: 60px;
    
        @media all and (max-width: 991px) {
            border-radius: 30px;
        }

        > p {
            @include dynamicFontSize(20, 28, 1200, 1600);
        }

        .blockquote__author-name {
            @include dynamicFontSize(16, 20, 1200, 1600);
        }
    }
}