.accordion {
    $block-name: '.accordion';
    color: inherit;

    &__item {
        border-radius: 24px;
        overflow: hidden;
        background-color: $colorWhite;
        transition: box-shadow 0.3s ease;

        &:not(:first-child) {
            margin-top: 24px;
        }

        &.open {
            box-shadow: 0 0 0 2px $colorSlate;
        }
    }
    
    &__header {
        position: relative;
        width: 100%;
        display: inline-flex;
        text-align: left;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        gap: 16px;
        padding: 24px;
        background-color: transparent;

        @screen lg {
            padding: 32px 0;
        }

        &.active {
            #{$block-name}__icon svg {
                transform: rotate(-180deg);
            }
        }
    }

    &__icon {
        position: relative;

        svg {
            transition: transform 0.3s ease;
        }
    }

    &__title {
        flex: 1;
        font-size: 18px;
        font-weight: 700;
        color: $colorSlate;
    }

    &__collapsible {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease;
    }

    &__content {
        padding: 0 24px 24px 24px;
    }
}

.faqs-category {
    display: grid;
    gap: 48px;

    @media (min-width: 991px) {
        gap: 60px;
    }

    @media (min-width: 1200px) {
        grid-template-columns: 1fr 2fr;
        gap: 120px;
    }

    &__aside {
        display: flex;
        flex-direction: column;
    }

    &__nav {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    &__link {
        display: block;
        padding: 12px 16px;
        border-radius: 24px;
        background-color: $colorWhite;
        font-weight: 700;
        color: $colorSlate;
        transition: color 0.3s ease, background-color 0.3s ease;

        &:hover,
        &.active {
            background-color: $colorSlate;
            color: $colorWhite;
        }
    }
}