
.one-line-form {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    border-radius: 50px;

    @media screen and (max-width: 768px) {
        display: block;
        border-radius: 0;
        box-shadow: none;
    }

    &--margin {
        margin-top: 2rem;

        @media screen and (max-width: 768px) {
            margin-top: 1rem;
        }
    }

    &__input {
        width: 100%;
        padding: 12px 24px;
        border-radius: 50px 0 0 50px;
        border: 0;
        background-color: $colorTransparentBlack;

        font: {
            size: rem(18px);
            weight: 300;
        }

        @media screen and (max-width: 768px) {
            display: block;
            margin-bottom: 1rem;
            border-radius: 50px;
        }

        &:focus {
            outline: none;
        }
    }

    &__button {
        padding: 12px 24px;
        background-color: $colorTeal;
        border-radius: 0 50px 50px 0;
        border: 0;
        white-space: nowrap;
        cursor: pointer;

        font: {
            size: 20px;
            weight: 700;
        }
        color: #fff;
        line-height: 24px;

        transition: opacity 0.3s;

        &:hover {
            // background-color: $colorWine;
            opacity: 0.7;
        }

        &:focus {
            outline: 0;
        }

        @media screen and (max-width: 768px) {
            display: block;
            width: 100%;
            border-radius: 50px;
        }
    }

    + .form__error {
        padding-top: 8px;
    }

    ::-webkit-input-placeholder {
        color: rgba(#fff, 0.3);
    }
    
    :-moz-placeholder {
        color: rgba(#fff, 0.3);
    }
    
    ::-moz-placeholder {
        color: rgba(#fff, 0.3);
    }
    
    :-ms-input-placeholder {
        color: rgba(#fff, 0.3);
    }
}