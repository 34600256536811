
// Play with the values to generate your custom menu !

$t-size:              22px;
$t-border-weight:     2px;
$t-border-color:      transparent;
$t-dot-size:          6px;
$t-line-distance:     6px;
$t-line-width:        20px;
$t-close-rotation:    135deg;

.burger {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    height: $t-size;
    width: $t-size;
    // padding: {
    //     left: 18px;
    //     right: 18px;
    // }
    // border-radius: 50px;
    padding: 7px;

    background-color: transparent;
    cursor: pointer;
    transition: all 0.5s 0.3s;

    @media screen and (min-width: 1025px) {
        display: none;
    }

    &:focus {
        outline: none;
    }

    &__line {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: $t-dot-size;
        width: $t-dot-size;
        border-radius: $t-dot-size;
        background-color: $colorSlate;
        transition: all 0.5s 0.3s;

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            height: $t-dot-size;
            width: $t-dot-size;
            border-radius: $t-dot-size;
            background-color: $colorSlate;
            top: 0;
            left: 0;
            transition: all 0.5s 0.3s;
        }

        &:before {
            transform: translateX(8px);
        }

        &:after {
            transform: translateX(16px);
        }

        &:nth-child(2) {
            left: 8px;
            top: 8px;

            &:before {
                transform: translateX(-8px);
            }

            &:after {
                transform: translateX(8px);
            }
        }

        &:nth-child(3) {
            top: 16px;
        }
    }

    // Active status
    &.active {
        transform: rotate(90deg);
        transition: all 0.5s;

        .burger__line {
            background-color: $colorWhite;
            transition: all 0.5s;

            &::before,
            &::after {
                background-color: $colorWhite;
                transition: all 0.5s;
            }

            &:nth-child(1) {
                &:before {
                    transform: translateX(16px);
                }
            }

            &:nth-child(2) {
                &:before {
                    transform: translateX(-8px) translateY(-8px);
                }

                &:after {
                    transform: translateX(8px) translateY(8px);
                }
            }

            &:nth-child(3) {
                &:before {
                    transform: translateX(0px);
                }
            }
        }
    }
}
