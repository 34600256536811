.section {
    position: relative;
    overflow: hidden;

    // default padding
    padding: {
        top: 48px;
        bottom: 48px;
    }

    @media all and (min-width: 768px) and (max-width: 1024px) {
        padding-top: 36px;
        padding-bottom: 36px;
    }

    @media all and (max-width: 767px) {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    // padding variations
    &--p-small {
        padding-bottom: 24px;
        padding-top: 24px;

        &-top {
            padding-top: 24px;
        }

        &-bottom {
            padding-bottom: 24px;
        }
    }

    &--p-medium {
        padding-top: 36px;
        padding-bottom: 36px;

        @media all and (max-width: 767px) {
            padding-top: 24px;
            padding-bottom: 24px;
        }

        &-top {
            padding-top: 36px;

            @media all and (max-width: 767px) {
                padding-top: 24px;
            }
        }

        &-bottom {
            padding-bottom: 36px;

            @media all and (max-width: 767px) {
                padding-bottom: 24px;
            }
        }
    }

    &--p-large {
        padding-top: 96px;
        padding-bottom: 96px;

        @media all and (min-width: 768px) and (max-width: 1024px) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        @media all and (max-width: 767px) {
            padding-top: 48px;
            padding-bottom: 48px;
        }

        &-top {
            padding-top: 96px;

            @media all and (min-width: 768px) and (max-width: 1024px) {
                padding-top: 60px;
            }
    
            @media all and (max-width: 767px) {
                padding-top: 48px;
            }
        }

        &-bottom {
            padding-bottom: 96px;

            @media all and (min-width: 768px) and (max-width: 1024px) {
                padding-bottom: 60px;
            }
    
            @media all and (max-width: 767px) {
                padding-bottom: 48px;
            }
        }
    }

    &--p-none {
        padding-top: 0;
        padding-bottom: 0;

        &-top {
            padding-top: 0;
        }

        &-bottom {
            padding-bottom: 0;
        }
    }

    &__animation {
        position: relative;
        max-height: 900px;
        
        &:before {
            content: "";
            display: block;
            padding-top: 46.88%;
        }
    }

    // header + footer
    &__header {
        padding-bottom: 2em;
    }

    &__footer {
        padding-top: 2em;
    }
    
    // useful
    &--vertically-aligned {
        display: flex;
        align-items: center;
    }
    
    &--btns-on-bottom {
        [class*="col-"] {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p:last-of-type {
                justify-content: flex-end;
            }
        }
    }

    &--negative-top-spacing {
        z-index: 2;

        @media (min-width: 1024px) {
            margin-top: -250px;
        }
    }

    &--border-radius {
        border-top-right-radius: 60px;
        border-bottom-left-radius: 60px;
        border-bottom-right-radius: 60px;

        
        @media all and (max-width: 991px) {
            border-top-right-radius: 30px;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
        }
    }

    &--overflow-visible {
        overflow: visible;
    }

    &--no-padding {
        padding: 0;
    }

    &__col {

        &-text {
            padding: 7.4%;

            @media all and (max-width: 991px) {
                padding-bottom: 0;
            }
        }

        &-image {

            &:before {

                @media all and (max-width: 991px) {
                    content: "";
                    display: block;
                    width: 100%;
                    padding-top: 70%;
                }
            }

            &-item {
                height: 100%;
                background-size: cover;
                background-position: center left;
                background-repeat: no-repeat;

                @media all and (max-width: 991px) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    background-position: top right;
                }
            }

            img {
                display: none;
            }
        }
    }

    &--radius {
        border-radius: 60px;

        @media all and (max-width: 991px) {
            border-radius: 30px;
        }
    }

    &--blockquote {

        &:after {
            @media all and (max-width: 991px) {
                content: "";
                display: block;
                width: 100%;
                padding-top: 35%;
            }
        }
    }

    &__blockquote-background {
        position: absolute;
        left: auto;
        top: 0;
        right: 0;
        height: 100%;
        width: 41%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right top;

        @media all and (max-width: 991px) {
            width: 100%;
            height: 100;
            background-position: right bottom;
            background-size: 50%;
        }

        img {
            display: none;
        }
    }


    &__bg-image {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 43%;
        background-position: 100%;
        // background-position: right center;
        background-position: right bottom;
        z-index: 2;

        @media all and (min-width: 992px) and (max-width: 1100px) {
            background-size: 55%;
        }

        @media all and (min-width: 575px) and (max-width: 991px) {
            padding-top: 48px;
            width: 100%;
            height: 100%;
            background-position: right bottom;
            background-size: 25%;
        }

        @media all and (max-width: 574px) {
            padding-top: 48px;
            width: 100%;
            height: 100%;
            background-position: right bottom;
            background-size: 40%;
        }

        &-rt {
            right: 0;

            @media all and (max-width: 991px) {
                background-position: right bottom;
            }
        }

        
        &-lt {

            @media all and (min-width: 1501px) {
                background-size: auto 90%;
            }

            @media all and (min-width: 992px) and (max-width: 1500px) {
                background-size: 45% auto;
            }

            @media all and (min-width: 992px) {
                // transform: scaleX(-1);
                // background-size: contain;
                background-position: left bottom;
                // background-size: auto 90%;
                left: 0;
            }

            @media all and (min-width: 575px) and (max-width: 991px) {
                transform: scaleX(-1);
            }

            @media all and (max-width: 574px) {
                background-size: auto 128px;
            }

            @media all and (max-width: 991px) {
                background-position: left bottom;
            }
        }

        img {
            display: none;
        }

        ~ [class*="container"] {
            z-index: 3;
        }
    }

    &--bg-image {
        padding: {
            top: 134px;
            bottom: 134px;
        }

        @media all and (max-width: 991px) {
            padding-top: 48px;
        }
    }
}
