
.ratio {
    position: relative;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &--50 {
        padding-bottom: 50%;
    }

    &--60 {
        padding-bottom: 60%;
    }
}