
.mask {

    &--circle {
        transform: scale(0.5);
        opacity: 0;
        transition: transform 1.5s 0.5s, opacity 1.5s 0.5s;

        @supports(-webkit-clip-path: circle(0% at center)) or (clip-path: circle(0% at center)) {
            transform: none;
            clip-path: circle(0% at center);
            transition: clip-path 3s ease-out;
        }

        &.animate {
            transform: scale(1);
            opacity: 1;

            @supports(-webkit-clip-path: circle(0% at center)) or (clip-path: circle(0% at center)) {
                transform: none;
                clip-path: circle(100% at center);
            }
        }
    }
}
