.splide {
    
    &--arrows-no {

        .splide__arrows {
            display: none;
        }
    }

    &--cards-person {
        
        @media all and (max-width: 640px) {
            margin-left: 24px;
        }

        .splide__list {
            display: flex;
        }

        .splide__arrow {
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: scaleX(-1) translateY(-50%);
            width: 3em;
            height: 3em;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            padding: 0;
            outline: none;
            cursor: pointer;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                width: 3em;
                height: 3em;
            }


            svg {
                width: 1.2em;
                height: 1.2em;
                fill: $colorWhite;
            }

            &--prev {
                left: 0rem; 
                background: $colorTeal;
                transition: left 0.3s ease;

                &:hover {
                    left: 1rem;
                }

                &:before {
                    left: 1.5rem;
                    background: $colorTeal;
                }

                svg {
                    transform: scaleX(1);
                }
            }

            &--next {
                right: 0rem; 
                background:$colorPink;
                transition: right 0.3s ease;

                
                &:hover {
                    right: 1rem;
                }

                &:before {
                    right: 1.5rem;
                    background: $colorPink;
                    z-index: -1;
                }

                svg {
                    transform: scaleX(-1);
                }
            }
        }
    }

    .card {

        @media all and(max-width: 991px) {
            padding: 0;
        } 
    }
}