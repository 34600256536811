

.select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    min-width: 200px;
    // padding: 17px 40px 14px 0;
    padding: 12px 40px 12px 0;

    font: {
        size: rem(20px);
        weight: 300;
    }
    color: $colorGrey;
    border-radius: 0;
    border: 0;
    border-bottom: 2px solid $colorGrey;
    background: url("/images/icons/chevron-down.svg") no-repeat;
    background-position: 100%;
    background-size: 28px 28px;
    background-color: transparent;
    transition: border 0.3s;

    &--full {
        width: 100%;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        border-color: $colorSlate;
    }

    &:focus {
        color: $colorSlate;
        border-color: $colorSlate;
    }

    &.filled {
        color: $colorPink;
        border-color: $colorPink;

        &:focus {
            color: $colorSlate;
            border-color: $colorSlate;
        }
    }

    &::-ms-expand {
        display: none;
    }
}
