.video-and-text {

    @media all and (min-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__item {

        &--video {
            position: relative;
            flex: 0 0 54%;

            @media all and (max-width: 991px) {
                margin-bottom: 24px;
            }
        }

        &--text {
            flex: 0 0 39%;
        }
    }

}
