/**
*
* Forms
*
**/


.form {
    position: relative;

    fieldset {
        @include clearfix();
    }

    label.error {
        position: absolute;
        top: 110%;
        left: 5%;

        font-size: rem(13px);
        color: $colorRed;
    }

    &__group {
        position:relative;
        margin-bottom: 0.6rem;

        @media all and (max-width: 567px) {
            margin-bottom: 1.6rem;
        }

        &--not-required {
            padding-bottom: 12px;
        }

        &--right {
            margin: {
                left: auto;
                right: 0;
            }
        }

        &--width {

            &-auto {
                flex: 0 1 auto!important;
                text-align: center;
            }

            &-half {
                flex: 0 1 50%!important;
            }
        }
    }

    &__input,
    &__textarea {
        display: block;
        padding: 24px 0 12px 0;
        font: {
            size: rem(20px);
            weight: 300;
        }
        width: 100%;
        color: $colorMain;
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid $colorGrey;
        transition: border 0.3s;
        background-color: transparent;

        &:focus {
            outline:none;
        }

        &:hover {
            border-color: $colorSlate;
        }

        &:focus,
        &.filled {

            border-color: $colorPink;

            /* active state */
            ~ .form__label {
                transform: translateY(-22px);
                font-size: 16px;
                font-weight: 700;
                color: $colorPink;
            }
        }

        &:disabled {
            opacity: 0.6;
        }

        &--no-border {
            border: 0;
        }

        &.error {
            border-color: $colorRed;
        }
    }

    &__label {
        color: $colorGrey;
        display: inline-block;
        padding: 0;
        border-radius: 0;
        font-size: 20px;
        font-weight: 300;
        position:absolute;
        pointer-events:none;
        left: 0;
        top: 23px;
        will-change: transform;
        transition: 0.3s ease all;
    }

    &__textarea {
        width: 100%;
        min-height: 5em;
    }

    &__required {
        display: inline-block;
        padding-top: 6px;
        font-size: 12px;
        color: $colorGrey;
        // opacity: 0.5;
        line-height: 1.1;
    }

    &__error {
        color: $colorRed;
        font-size: 14px;
    }

    &--two-column {
        @media (min-width: 568px) {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }

        > * {
            &.form__group {
                @media (min-width: 568px) {
                    flex-grow: 1;
                    flex: 0 1 48%;
                    margin: 12px 0;
                }

                .col-lg-5 & {
                    @media all and (min-width: 992px)  and (max-width: 1200px) {
                        flex: 0 1 100%;
                    }
                }
    
                &--full-width {
                    flex: 1 1 100%;
    
                    .checkbox__label {
                        padding: {
                            bottom: 4px;
                        };
                    }
                }
            }

            &:not(.form__group) {
                flex: 1 1 100%;
            }
        }


    }

    &__row {
        a {
            &:not([class]) {
                color: inherit;
                text-decoration: underline;
                transition: opacity 0.3s;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        &--flex {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }

    &__upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
        width: 100%;

        .btn {
            pointer-events: none;
            cursor: pointer;
        }

        input[type=file] {
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
}


// Checkbox & Radio inputs
.form__radio, 
.form__checkbox {
    position: absolute; 
    left: -999em;
}

.form__radio-label,
.form__checkbox-label {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}
        
.form__radio-label:before,
.form__checkbox-label:before { 
    display: inline-block; 
    position: relative; 
    top: 2px; 
    left: -2px; 
    content:''; 
    width: 25px; 
    height: 23px;
    background: url(../images/formelements.png) top left no-repeat; 
}

.form__checkbox + .form__checkbox-label:before {
    background-position: 0 -25px;
}

.form__checkbox:checked + .form__checkbox-label:before {
    background-position: 0 0 ;
}
         
.form__radio + .form__radio-label:before {
    background-position: -25px -25px;
}         

.form__radio:checked + .form__radio-label:before {
    background-position: -25px 0;
}