@use 'sass:math';

$mainContainerMaxWidth: 1244;
$containerSizes: (
    micro: 448,
    tiny: 612,
    super-narrow: 740,
    narrow: 824,
    large: 1500
);

.container {
    position: relative;
    margin-right: auto;
    margin-left: auto;

    width: 92%;
    max-width: ($mainContainerMaxWidth)+px;

    @each $containerName, $containerValue in $containerSizes {
        &-#{$containerName} {
            @extend .container;
            max-width: ($containerValue)+px;

            &--half {
                max-width: (math.div($containerValue, 2))+px;
            }

            &--right {
                margin-right: 0;

                @media all and (max-width: 991px) {
                    margin-right: auto;
                }
            }

            &--left {
                margin-left: 0;

                @media all and (max-width: 991px) {
                    margin-left: auto;
                }
            }
        }
    }

    &-fluid {
        padding-left: 0;
        padding-right: 0;
        overflow-x: hidden;
    }

    &--border-top {
        padding-top: 48px;
        border-top: 1px solid $colorTransparentBlack;
    }

    &--radius {
        border-radius: 60px;
        overflow: hidden;

        @media all and (max-width: 991px) {
            border-radius: 30px;
        }
    }

    &-blog {
        width: 82%;
        margin-right: auto;
        margin-left: auto;
    }

    &-padding-mobile {
        
        @media all and (max-width: 991px) {
            padding: {
                left: 12px;
                right: 12px;
            };
        }
    }
}
