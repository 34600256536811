/**
*
* List
*
**/

// General
ul,
ol {
    padding: 0;
    margin: 0;
}

ul,
.list-ol {
    list-style-type: none;
}

.list-ul,
.list-ol {
    padding-top: 0.7em;
    padding-bottom: 0.7em;

    li {
        padding: 1px 0 1px 0;
        position: relative;
        margin-bottom: 14px; //2px

        &:before {
            position: absolute;
            overflow: hidden;
            display: block;
            content: " ";
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

// Unordered list
.list-ul {
    padding-left: 25px;

    li {
        &::before {
            top: 6px;
            left: -25px;

            width: 12px;
            height: 12px;
            border-radius: 14px;
            // background-color: $colorWhite;
            box-shadow: 0 0 0px 2px $colorPink inset;
        }
    }

    @each $colorName, $color in $colors {
        &.list-#{$colorName} {
            li {
                &::before {
                    box-shadow: 0 0 0px 2px $color inset;
                }
            }
        }
    }
}

// Ordered list
.list-ol {
    counter-reset: item;
    padding-left: 30px;

    li {
        &::before {
            left: -30px;

            width: 25px;
            content: counters(item, ".") ". ";
            counter-increment: item;

            text-align: left;
            line-height: 1.25;
            font-weight: 700;
            color: $colorTeal;
        }
    }

    &--pink {
        li::before {
            color: $colorPink;
        }
    }

    @each $colorName, $color in $colors {
        &.list-#{$colorName} {
            li {
                &::before {
                    color: $color;
                }
            }
        }
    }
}

// Other types of lists
.list-2-cols,
.list-3-cols,
.list-4-cols {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

	li {
        width: 100%;

        margin-bottom: 10px;
	}
}

.list-2-cols {
    li {
        @media (min-width: 501px) {
            width: 47%;
        }
    }
}

.list-3-cols {
    li {
        @media (min-width: 768px) {
            width: 30%;
        }
    }
}

.list-4-cols {
    li {
        @media (min-width: 500px) and (max-width: 991px) {
            width: 50%;
        }

        @media (min-width: 992px) {
            width: 22%;
        }
    }
}

.content .list--no-bullet,
.list--no-bullet {
    li:before {
        display: none;
    }
}


.list-of-logos {
    margin-top: 24px;
    list-style-type: none;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 601px) and (max-width: 991px) {
        justify-content: space-around;
    }

    > li {
        flex-basis: calc(50% - 32px);
        margin-bottom: 64px;

        @media (min-width: 601px) and (max-width: 991px) {
            flex-basis: calc(33% - 40px);
            margin-bottom: 48px;
        }

        @media (max-width: 600px) {
            flex-basis: calc(50% - 12px);
            margin-bottom: 24px;
        }

        // &:last-child,
        &:nth-last-child(-n+2) {
            margin-bottom: 0;
        }

        // &:nth-last-child(3n - 3) {
        //     @media (min-width: 601px) and (max-width: 991px) {
        //         margin-bottom: 0;
        //     }
        // }
    }
}
