.pricing-table {
  position: relative;
  background-color: $colorOffWhite;
  
  .splide__pagination-wrapper {
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 0;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    pointer-events: none;
  
    .splide__pagination {
      pointer-events: all;
    }
  }
  
  .compact-table {
    background-color: $colorWhite;

    @media (min-width: 991px) {
      display: none;
    }
  }
  
  .compact-page {
    $block-name: '.compact-page';

    &__header  {
      background-color: $colorOffWhite;
      padding: 16px;

      &--purple,
      &--pink,
      &--teal {
        text-align: center;
        padding: 0 48px;

        #{$block-name}__headline {
          color: $colorWhite;
          padding: 16px 0;
          border-radius: 12px;
        }
      }
      
      &--purple {
        #{$block-name}__headline {
          background-color: $colorPurple;
        }
      }

      &--pink {
        #{$block-name}__headline {
          background-color: $colorPink;
        }
      }

      &--teal {
        #{$block-name}__headline {
          background-color: $colorTeal;
        }
      }
    }

    &__row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 16px;
      
      &:not(:last-child) {
        border-bottom: 1px solid $colorOffWhite;
      }

      .headline-4 {
        margin-bottom: 8px;
      }

      &--empty {
        padding: 32px;
      }
    }

    &__title {
      font-size: 18px;
      margin: 0 0 4px 0;
    }

    &__text {
      margin: 0;
      font-size: 14px;
    }

    &__symbol {
      display: flex;
      justify-content: center;
      align-items: center;

      .check {
        width: 20px;
        height: 20px;
        background-color: $colorPink;
        mask: url('/images/icons/check-circle.svg') no-repeat center;
      }
      
      .hyphen {
        width: 12px;
        height: 12px;
        background-color: $colorPink;
        mask: url('/images/icons/hyphen.svg') no-repeat center;
      }
    }
  }

  .extended-table-wrapper {
    padding: 60px 0;
    display: none;

    @media (min-width: 991px) {
      display: block;
    }
  }

  .extended-table {
    $block-name: '.extended-table';
    padding: 24px;
    border-radius: 30px;
    background-color: $colorWhite;

    &__header {
      display: grid;
      gap: 16px;
      grid-template-columns: 40% repeat(3, 1fr);
      background-color: $colorOffWhite;
      border-radius: 15px;

      #{$block-name}__headline {
        padding: 16px 24px;
        border-radius: 15px;

        &--purple {
          background-color: $colorPurple;
          color: $colorWhite;
          text-align: center;
        }

        &--pink {
          background-color: $colorPink;
          color: $colorWhite;
          text-align: center;
        }

        &--teal {
          background-color: $colorTeal;
          color: $colorWhite;
          text-align: center;
        }
      }
    }

    &__title {
      font-size: 18px;
      margin: 0 0 4px 0;
    }

    &__text {
      margin: 0;
      font-size: 14px;
    }

    &__row {
      display: grid;
      gap: 16px;
      grid-template-columns: 40% repeat(3, 1fr);

      &:not(:last-child) {
        border-bottom: 1px solid $colorOffWhite;
      }

      #{$block-name}__col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px;
      }
    }

    &__symbol {
      display: flex;
      justify-content: center;
      align-items: center;

      .check {
        width: 20px;
        height: 20px;
        background-color: $colorPink;
        mask: url('/images/icons/check-circle.svg') no-repeat center;
      }
      
      .hyphen {
        width: 12px;
        height: 12px;
        background-color: $colorPink;
        mask: url('/images/icons/hyphen.svg') no-repeat center;
      }
    }
  }
}
