/**
*
* Top header
*
**/

.l-header {
    position: relative;
    z-index: 1;
    padding: 96px 0;

    @media all and (min-width: 768px) and (max-width: 1024px) {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    @media all and (max-width: 767px) {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    &--single-entity {
        padding: 1.5em 0 0 0;

        .row--g-12 > [class*="col-"] {
            padding-bottom: 0;
        }

        .breadcrumbs {
            padding-left: 48px;
            margin: 6px 0;

            @media all and (max-width: 991px) {
                padding-left: calc(4% + 12px);
            }
        }

        &__image.col-lg-8 {
            @media (min-width: 992px) {
                padding-right: 1.5em;
            }
        }
    }

    &--overflow-hidden {
        overflow: hidden;
    }

    &--large {
        padding: 250px 0 300px 0;

        @media all and (max-width: 991px) {
            padding: 96px 0;
        }
    }

    &--bottom-large-spacing {
        @media (min-width: 1024px) {
            padding: 96px 0 280px 0;
        }
    }

    &--small {
        padding: 48px 0;

        @media all and (min-width: 768px) and (max-width: 1024px) {
            padding-top: 36px;
            padding-bottom: 36px;
        }
    
        @media all and (max-width: 767px) {
            padding-top: 24px;
            padding-bottom: 24px;
        }
    }

    &--no-padding {
        padding: 0;

        &-bottom {
            padding-bottom: 0;
        }
    }

    &--half-img-txt {
        position: relative;
        overflow: hidden;
    }

    &--full-height {
        padding: 0;
        display: flex;
        flex-direction: row;

        .l-header__container {
            min-height: calc(100vh - 100px);
            // height: 600px;
            display: flex;
            align-items: center;
            padding: 96px 0;

            @supports (-webkit-overflow-scrolling: touch) {
                min-height: calc(var(--vh, 1vh) * 100);
            }

            @media screen and (min-width: 200px) and (max-width: 1024px) {
                min-height: calc(100vh - 76px);
                padding: 12px 0 48px 0;

                @supports (-webkit-overflow-scrolling: touch) {
                    min-height: calc((var(--vh, 1vh) * 100) - 76px);
                }
            }

            .l-header__cta-button {
                position: absolute;
                left: 0;
                bottom: 0;
            }

        }
    }


    &--with-breadcrumbs {
        padding-top: 24px;

        .breadcrumbs {
            padding-left: 48px;
            padding-bottom: 8px;
            margin-bottom: 64px;

            @media all and (max-width: 991px) {
                padding-left: calc(4% + 12px);
            }
        }

        &.l-header--large {
            .breadcrumbs {
                padding-bottom: 224px;

                @media all and (max-width: 991px) {
                    padding-bottom: 8px;
                    margin-bottom: 64px;
                }
            }
        }

        &.l-header--no-padding {
            padding: 0;

            .breadcrumbs {
                @media all and (min-width: 992px) {
                    position: absolute;
                    z-index: 2;
                }

                @media all and (max-width: 991px) {
                    margin-bottom: 0;
                }
            }
        }

        [class*="container"] {
            > *:not(.row) {
                @media all and (max-width: 991px) {
                    padding-left: 12px;
                }
            }
        }
    }

    &--half-over {
        padding-bottom: 0;

        .btn-back {
            position: absolute;
            top: 24px;
            left: 24px;


            @media all and (max-width: 658px) {
                position: relative;
                z-index: 1;
                top: -24px;
                left: 4%;
            }
        }
    }

    &__img-half {
        // padding-bottom: 40%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 400px;
        border-radius: 60px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @media all and (max-width: 991px) {
            border-radius: 30px;
        }

        &--full-height {
            height: 100%;

            @media all and (max-width: 991px) {
                position: static;
                padding-bottom: 40%;
            }
        }
    }

    &__column-fixer {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__container {

        .btn-back {
            position: absolute;
            top: 24px;
            left: calc(8.333333% + 12px);

            @media all and (max-width: 991px) {
                left: calc(8.333333% + 12px);
            }
        }

        &--bottom-radius {
            padding: 48px 0 0 0;
            border-radius: 0 0 60px 60px;

            @media all and (max-width: 991px) {
                border-radius: 0 0 30px 30px;
                padding: 48px 0 8px 0;
            }

            .btn-back {
                left: calc(8.333333% + 12px);

                @media all and (max-width: 991px) {
                    left: 12px;
                }
            }
        }
    }

    &__text-col {
        padding: 96px 0;

        @media all and (max-width: 991px) {
            padding: 24px 0;
        }
    }

    &__img-col {
        > div {
            height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            @media all and (max-width: 991px) {
                padding-bottom: 56.25%;
            }
        }
    }

    &__cta-button {
        background-color: $colorTeal;
        margin: 0;
        padding: 20px 20px 32px 20px;
        border-radius: 12px 12px 0 0;
        cursor: pointer;
        transform: translateY(12px);
        transition: transform 0.8s;

        &:hover {
            transform: translateY(0px);
        }

        &:focus {
            outline: 0;
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-right: inherit;
        text-align: right;
        transform: translateY(50%);

        @media screen and (max-width: 991px) {
            position: relative;
            z-index: 2;
            transform: none;
            padding: 12px 0 0 0;
        }
    }

    $timeoutValue: 0.35;
    &__headline-animation {
        > * {
            display: block;

            @media screen and (min-width: 992px) {
                opacity: 0;
                visibility: hidden;
                transform: translateY(-15px);
            }

            @for $number from 1 through 5 {
                &:nth-child(#{$number}) {
                    transition: transform ($timeoutValue)+s ($number * $timeoutValue)+s, opacity ($timeoutValue)+s ($number * $timeoutValue)+s, visibility ($timeoutValue)+s ($number * $timeoutValue)+s;
                }
            }
        }

        &.animate {
            > * {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }
    }

    &__content-animation {

        @media screen and (min-width: 992px) {
            opacity: 0;
            visibility: hidden;
            transition: opacity ($timeoutValue)+s 2s, visibility ($timeoutValue)+s 2s;
        }

        &.animate {
            opacity: 1;
            visibility: visible;
        }
    }

    &__video {

        &:before {
            content: "";
            display: block;
            padding-top: 56.25%;
        }
    }

    &__top-rounded-image {
        width: 100%;
        padding-bottom: 45%;
        border-radius: 60px 60px 0 0;

        background-size: cover;
        background-position: center;

        img {
            display: none;
        }
    }

    &__floating-form {
        @media (max-width: 991px) {
            display: none;
        }

        @media (min-width: 992px) {
            position: absolute;
        }
    }
}


@keyframes fastUpScale {
    0%,
    100% {
        opacity: 1;
        transform: scale(1);
    }

    70% {
        opacity: 0.5;
        transform: scale(2);
    }
}