.marketo {
    .mktoForm {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        font-family: 'museo-sans-rounded', sans-serif !important;

        @media screen and (min-width: 568px) {
            flex-flow: row wrap;
            justify-content: space-between;
        }
    }

    .mktoFormRow {
        flex: 0 1 48%;
        margin: 12px 0;

        &:nth-child(n + 8) {
            flex: 1 1 100%;
        }

        .mktoFieldDescriptor.mktoFormCol {
            width: 100%;
            margin-bottom: 0 !important;

            .mktoFieldWrap {
                width: 100%;
            }

            .mktoOffset {
                height: 0 !important;
            }
        }
    }

    .mktoLabel {
        width: 100% !important;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        color: $colorGrey;
        font-weight: 300 !important;
        font-size: 16px;

        & + .mktoGutter {
            height: 0 !important;
        }
    }

    .mktoField {
        width: 100% !important;
        color: $colorSlate;
        background-color: transparent;
        font-size: 1.25rem !important;
        padding: 12px 0 !important;
        border: 0;
        border-bottom: 2px solid $colorGrey;
        
        &:focus {
            outline: 0;
        }
    }

    textarea {
        min-height: 5em;
    }

    .mktoFieldWrap.mktoRequiredField {
        position: relative;
        padding-bottom: 20px;
        
        .mktoAsterix {
            position: absolute;
            bottom: 0;
            display: inline-block;
            padding: 6px 0 0 0;
            font-size: 12px;
            font-weight: 300;
            line-height: 1.1;
            color: $colorGrey;
            visibility: hidden;
    
            &::before {
                content: 'Required';
                position: relative;
                visibility: visible;
            }
        }
    }
    
    .mktoButtonRow {
        flex: 1 1 100%;
        text-align: right;

        .mktoButtonWrap {
            margin-left: 0 !important;
            
            .mktoButton {
               cursor: pointer;
               box-shadow: 0 0 0px 2px $colorTeal inset;
               color: $colorWhite;
               display: inline-block;
               vertical-align: middle;
               position: relative;
               z-index: 1;
               padding: 12px 24px !important;
               border-radius: 50px;
               font-size: 20px !important;
               font-weight: 700;
               line-height: 1;
               overflow: hidden;
               transition: color .4s ease;
               background-color: transparent !important;
               background-image: none !important;
               border: 0 !important;

               @media (min-width: 575px) {
                   font-size: 24px !important;
               }

               &:before,
               &:after {
                   content: ' ';
                   position: absolute;
                   top: 0;
                   left: 0;
                   z-index: -1;
                   width: 100%;
                   height: 100%;
                   border-radius: 50px;
                   background-color: $colorTeal;
                   will-change: transform, color;
                   transition: transform 0.4s ease;
               }
   
               &:before {
                   transform: translateX(0%);
               }
   
               &:after {
                   transition: transform 0s 0.4s ease;
               }

               &:hover {
                   color: $colorTeal;

                    &:before {
                        transform: translateX(-105%);
                        transition: transform 0s ease;
                    }

                    &:after {
                        transform: translateX(105%);
                        transition: transform 0.4s ease;
                    }
               }
            }
        }
    }

    select.mktoField {
        option {
            color: $colorBlack;
        }
    }


    // chceckboxes list

    fieldset.mktoFormCol {
        padding-right: 0 !important;
        margin-bottom: 0 !important;

        legend {
            margin: 0;
            padding: 0.3em;
        }

        .mktoCheckboxList {
            label {
                position: relative;
                display: block;
                padding: 0 0 0 32px;
                font-size: 14px;
                min-height: 24px;
                margin-left: 0;

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    transition: transform 200ms ease, border-color 200ms ease, background-color 200ms ease, box-shadow 200ms ease,
                }

                &::before {
                    width: 24px;
                    height: 24px;
                    background-color: rgba(0,0,0,.1);
                    border: 0;
                    border-radius: 4px;
                    left: 0;
                    top: 0;
                }
            }

            input {
                &:checked {
                    & + label {
                        &::before {
                            border-color: $colorPink;
                            background-color: $colorPink;
                        }
        
                        &::after {
                            top: 5px;
                            left: 5px;
                            width: 14px;
                            height: 14px;
                            border-radius: 14px;
                            background-color: $colorPink;
                            box-shadow: 0 0 0px 2px $colorWhite inset;
                        }
                    }
                }
            }
        }
    }

    // consent checkbox

    // hide real input
    .mktoCheckboxList {
        width: 100% !important;

        input {
            display: inline-block;
            opacity: 0;
            width: 0 !important;
            margin: 0;
            overflow: hidden;
            height: 0;
        }
    }

    // position label text
    label#LblmarketingConsent.mktoLabel,
    label#LblGDPR_Opt_in__c.mktoLabel {
        color: $colorSlate;
        padding: 0 0 0 32px;
        font-size: 14px;
        min-height: 24px;
        
        .mktoAsterix {
            margin-left: -32px;
        }
    }


    // style fake checkbox
    label#LblmarketingConsent:not(.mktoLabel),
    label#LblGDPR_Opt_in__c:not(.mktoLabel) {
        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            transition: transform 200ms ease, border-color 200ms ease, background-color 200ms ease, box-shadow 200ms ease,
        }

        &::before {
            width: 24px;
            height: 24px;
            background-color: rgba(0,0,0,.1);
            border: 0;
            border-radius: 4px;
            left: 0;
            top: 0;
        }
    }

    // style checked state of fake checkbox
    .mktoField#marketingConsent,
    .mktoField#GDPR_Opt_in__c {
        &:checked {
            & + label#LblmarketingConsent:not(.mktoLabel),
            & + label#LblGDPR_Opt_in__c:not(.mktoLabel) {
                &::before {
                    border-color: $colorPink;
                    background-color: $colorPink;
                }

                &::after {
                    top: 5px;
                    left: 5px;
                    width: 14px;
                    height: 14px;
                    border-radius: 14px;
                    background-color: $colorPink;
                    box-shadow: 0 0 0px 2px $colorWhite inset;
                }
            }
        }
    }

    // end of consent checkbox

    .response {
        padding: 16px;
        flex: 1 1 100%;
        margin: 24px 0;
        font-size: 16px;
        font-weight: 700;
        color: white;
    }

    
    &--one-column-fields {
        .mktoForm {
            flex-direction: column;
        }

        .mktoFormRow {
            flex: 1 1 100%;
        }
    }

    &.bg-slate {
        .mktoField,
        label#LblmarketingConsent.mktoLabel,
        label#LblGDPR_Opt_in__c.mktoLabel,
        fieldset.mktoFormCol {
            color: $colorWhite;
        }
    }
}