/*==========  GLOBAL STYLES  ==========*/

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body,
button {
    -moz-osx-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

html {
    background: #fff;
    // scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    font-family: 'museo-sans-rounded', sans-serif;
    font-size: $FontSizeDefault;
    line-height: 1.25;
    font-weight: 300;
    color: $colorMain;
    overflow-x: hidden;

    @media screen and (max-width: 767px) {
        font-size: $FontSizeSmall;
    }

    &.nav-open {
        @media screen and (max-width: 1024px) {
            position: fixed;
            overflow-y: scroll;
            width: 100%;
        }
    }
}

ul, li {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;

    &:not([class]) {
        color: inherit;
    }
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.is-affixed .inner-wrapper-sticky {
    z-index: 3;
}