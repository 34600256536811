.block {
    padding: 48px 60px;

    @media all and (max-width: 991px) {
        padding: 24px;
    }

    &--normal-padding {
        padding: 48px;

        @media all and (max-width: 991px) {
            padding: 24px;
        }
    }

    &--medium-padding {
        padding: 36px;

        @media all and (max-width: 991px) {
            padding: 24px;
        }
    }

    &--small-padding {
        padding: 24px;
    }
    
    &--no-padding {
        padding: 0;
    }

    &--large-y-padding {
        padding: 96px 0;

        @media all and (max-width: 991px) {
            padding: 24px 0;
        }
    }

    &--large-x-padding {

        @media all and (min-width: 992px) {
            padding:  {
                left: 96px;
                right: 96px;
            }
    
        }

        &+.block--large-x-padding {

            @media all and (min-width: 992px) and (max-width: 1400px) {
                padding-left: 0;
            }
        }
    }

    &--p-none {

        &-top {

            @media all and (min-width: 992px) {
                padding-top: 0;
            }
        }

        &-bottom {
            @media all and (min-width: 992px) {
                padding-bottom: 0;
            }
        }

    }

    &--large-padding {
        padding: 115px;

        @media all and (max-width: 991px) {
            padding: 24px;
        }
    }

    &--full-height {
        height: 100%;
    }

    &--radius {
        border-radius: 60px;

        @media all and (max-width: 991px) {
            border-radius: 30px;
        }

        &-large {
            border-radius: 80px;

            @media all and (max-width: 991px) {
                border-radius: 30px;
            }
        }
    }

    &--border {
        border: 1px solid $colorOffWhite;
    }

    &--one-col-padding {
        padding: {
            left: calc(1500px/12);
            right: calc(1500px/12);
        };

        @media all and (max-width: 1500px) {
            padding: {
                left: 39px;
                right: 39px;
            };
        }

        &-right {
            padding: {
                right: calc(1500px/12);
            };
    
            @media all and (min-width: 992px) and (max-width: 1500px) {
                padding-right: calc(92%/12);
            }

            @media all and (max-width: 991px) {
                padding: {
                    left: 39px;
                    right: 39px;
                };
            }
        }
    }

    &--mobile-no-padding {

        @media all and (max-width: 991px) {
            padding: 0 15px;
        }
    }

    & + .block:not(.block--mobile-no-padding) {
        @media all and (max-width: 991px) {
            padding-top: 48px;
        }
    }

    &-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px !important;

        p {
            flex-grow: 1;
        }

        .btn {
            margin-top: 0.6em;
        }
    }

    &.bg-white {
        
        .form {
            &__input,
            &__textarea {
                color: $colorBlack;
            }
        }
        
    }

    &--full-width {
        width: 100%;
    }
}
