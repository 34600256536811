/**
*
* Pagination
*
**/

.pagination {
    clear: both;
    padding: 1em 0;
    text-align: center;

    li,
    a,
    span {
        display: inline-block;
        font-weight: bold;
    }

    li {

        a, span {
            padding: .3em 1em;
            border-radius: 5px;
            background: white;
            color: #222;
    
            
        }

        &:not(.disabled) {
            a, span {
                &:hover {
                    background: $colorLightGrey;
                }
            }
        }

        &.active {

            a,
            span {
                background: $colorSlate;
                color: white;
                cursor: default;
            }

            &:hover {
                a, span {
                    background-color: $colorSlate;
                }
            }

        }

    } 

    
}