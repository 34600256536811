
.two-colors {
    position: relative;
    overflow: hidden;

    &--top {
        @each $colorName, $color in $colors {
            &-#{$colorName} {
                &:before {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 0;
                    width: 100%;
                    height: 100%;
                    background-color: $color;
                }
            }
        }
    }

    &--bottom {
        @each $colorName, $color in $colors {
            &-#{$colorName} {
                &:before {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    z-index: 0;
                    width: 100%;
                    height: 50%;
                    background-color: $color;
                }
            }
        }
    }
}