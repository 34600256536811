
$shapeVisible: (
    full: 0%,
    half: 50%,
    1qt: 75%,
    3qt: 35%
);

$positionsY: ('top', 'bottom', 'center');
$positionsX: ('left', 'right');

.shape {
    position: absolute;

    height: 100%;

    &--size {
        &-default {
            width: 35vw;
            height: 35vw;

            @media all and (min-width: 2200px) and (max-width: 2599px) {
                width: 30vw;
                height: 30vw;
            }

            @media all and (min-width: 2600px) and (max-width: 3899px) {
                width: 20vw;
                height: 20vw;
            }

            @media all and (min-width: 3900px){
                width: 700px;
                height: 700px;
            }
        }

        &-small {
            width: 17vw;
            height: 17vw;

            // @media all and (min-width: 2200px) and (max-width: 2599px) {
            //     width: 30vw;
            //     height: 30vw;
            // }

            // @media all and (min-width: 2600px) and (max-width: 3899px) {
            //     width: 20vw;
            //     height: 20vw;
            // }

            // @media all and (min-width: 3900px){
            //     width: 700px;
            //     height: 700px;
            // }
        }

        &-half {
            width: 50vw;
            height: 50vw;
        }

        &-full {
            width: 100vw;
            height: 100vw;
        }
    }

    &--position {
        &-left {
            left: 0;
        }

        &-right {
            right: 0;
        }

        &-top {
            top: 0;
        }

        &-bottom {
            bottom: 0;
        }

        &-center {
            top: 50%;
        }
    }

    &--visible {
        &-x {

            @each $visibleName, $value in $shapeVisible {
                &-#{$visibleName} {

                    @each $position in $positionsX {
                        &.shape--position-#{$position} {
                            @if $position == 'left' {
                                transform: translateX(-$value);
                            } @else if $position == 'center' {
                                transform: translateX(-50%);
                            } @else {
                                transform: translateX($value);
                            }
                        }
                    }

                    @each $visibleName2, $value2 in $shapeVisible {
                        &.shape--visible-y-#{$visibleName2} {

                            @each $positionY in $positionsY {
                                &.shape--position-#{$positionY} {
                                    @if $positionY == 'left' {
                                        transform: translate(-$value, $value2);
                                    } @else {
                                        transform: translate($value, $value2);
                                    }

                                    @each $positionX in $positionsX {
                                        &.shape--position-#{$positionX} {

                                            @if $positionY == 'top' {
                                                @if $positionX == 'left' {
                                                    transform: translate(-$value, -$value2);
                                                } @else {
                                                    transform: translate($value, -$value2);
                                                }
                                            } @else if $positionY == 'center' {
                                                transform: translate($value, -50%);
                                            } @else {
                                                @if $positionX == 'left' {
                                                    transform: translate(-$value, $value2);
                                                } @else {
                                                    transform: translate($value, $value2);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        overflow: hidden;

        ~ * {
            &:not(.l-header__footer):not(.btn-back):not(.breadcrumbs--wide) {
                position: relative;
                z-index: 1;
            }
        }
    }

    &--hover-content {

        @media all and (min-width: 992px) {
            z-index: 1;
        }
    }

    &--hide-on {

        &-tablet {
            @media all and (max-width: 991px) {
                display: none;
            }
        }
    }

    &--blockquote {
        position: absolute;
        width: 41.666667%;

        @media all and (max-width: 640px) {
            width: 35vw;
            height: 35vw;
            top: auto;
            bottom: 0;
            transform: translate(35%, 35%)!important;
            overflow: hidden;
        }
    }
}
