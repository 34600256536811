.checkbox {
    display: inline;
    opacity: 0;
    width: 0;
    margin: 0;
    overflow: hidden;
    -webkit-appearance: none;

    &__container {
        // height: 50px;
        margin-bottom: 14px;
    
        &.checkbox__container_inline {
            display: inline-block;
        }

        
        &--box {
            height: auto;
            margin-bottom: 0;

            .checkbox__label {
                font-size: 14px;
                line-height: 1.25;

                /* IE Edge 12+ */ 
                @supports (-ms-accelerator:true) {
                    line-height: 1.5; 
                }
                
                &:before {
                    // border-radius: 0;
                }
            }
        }   

        .checkbox {

            &--absolute {
                @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) { 
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    &__label {
        display: block;
        height: 100%;
        position: relative;
        padding: 15px 10px 15px 30px;
        cursor: pointer;
        line-height: 1.5;
        vertical-align: bottom;
        //color: $colorSlate;
        font: {
            size: 14px;
            weight: 300;
        }
        transition: color 200ms ease;
    
        &.checkbox__label_no-text {
            color: transparent;
            font-size: 0;
            width: 20px;
            padding-right: 0;
            padding-left: 20px;
        }
    
        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            transition: transform 200ms ease, border-color 200ms ease, background-color 200ms ease;
        }
    
        &:before {
            width: 24px;
            height: 24px;
            background-color: rgba($colorBlack, 0.1);
            border: 0;
            border-radius: 4px;
            left: 0;
            top: 13px;
        }

        &:after {
            border-color: #fff;
        }
    
        &:hover {
            &:before {
                background-color: rgba($colorBlack, 0.2);
            }
        }

        &--small {
            font-size: 14px;
            line-height: 1.25;
        }
    }
 
    &:checked {
        & + .checkbox__label {

            &:before,
            &:hover:before {
                border-color: $colorPink;
                background-color: $colorPink;
            }

            &:after {
                top: 18px;
                left: 5px;
                width: 14px;
                height: 14px;
                border-radius: 14px;
                background-color: $colorPink;
                box-shadow: 0 0 0px 2px $colorWhite inset;
            }
        }


        // @each $colorName, $color in $colors {
        //     .checkbox__container--box-#{$colorName} & {
        //         + .checkbox__label:before {
        //             border-color: $color;
        //             background-color: $color;
        //         }
                
        //     }
        // }
    }

}
