.horizontal-nav {
    height: 65px;

    &__menu {
        overflow-x: auto;
        white-space: nowrap;

        li {
            display: inline-block;
            margin-right: 2em;
        }

        &-link {
            position: relative;
            display: inline-block;
            padding: 1em 0;

            font-size: 20px;
            font-weight: 600;
            color: $colorMain;

            &:after {
                content: '';

                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 5px;
                opacity: 0;
                background-color: $colorPink;
                border-radius: 4px 4px 0 0;

                transition: opacity 0.2s ease;
            }

            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    .inner-wrapper-sticky {
        transition: margin-top .5s, top 0.6s ease-out;
    }
}

.l-navbar:not(.l-navbar--scrolled) + div {
    .is-affixed .inner-wrapper-sticky {
        @media (max-width: 1024px) {
            top: 73px !important;
        }

        @media (min-width: 1025px) {
            top: 102px !important;
        }
    }
}