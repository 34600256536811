.lottie {
    position: relative;
    margin: 0 auto;
    width: 500px;
    height: 500px;

    @media all and (min-width: 992px) and (max-width: 1400px) {
        width: 300px;
        height: 300px;
    }

    @media all and (max-width: 640px) {
        width: 300px;
        height: 300px;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
    }

    &-canvas {
        position: absolute;
        top: 0;
        left: 0;
    }

    &--large {
        position: relative;
        margin: 0 auto;
        width: 600px;
        height: 600px;
    
        @media all and (min-width: 992px) and (max-width: 1400px) {
            width: 300px;
            height: 300px;
        }

        @media all and (min-width: 641px) and (max-width: 991px) {
            width: 450px;
            height: 450px;
        }
    
        @media all and (max-width: 640px) {
            width: 200px;
            height: 200px;
        }
    }
}