.social-media {
    &__item,
    &__link {
        display: inline-block;
        vertical-align: middle;
    }

    &__item {
        &:not(:last-child) {
            margin-right: 12px;
        }
    }

    svg {
        transition: fill 0.2s ease;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 48px;
        background-color: $colorTransparentBlack;
        transition: background-color 0.2s ease;

        &:hover {
            // background-color: $colorGrey;

            svg {
                fill: $colorPink !important;
            }
        }
    }
}