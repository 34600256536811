
.table-list {
    display: table;

    &__row {
        display: table-row;
    }

    &__cell {
        display: table-cell;
        padding: 16px 24px 0 0;
    }
}