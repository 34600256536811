
.card {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    outline: none;

    &:hover {

        .card__headline {
            opacity: 0.8;
        }
    }

    &--rounded {
        border-radius: 30px;
    }

    &--bordered {
        border: 1px solid #E5E5E5;

        .card__content {
            border-top: 1px solid #E5E5E5;
        }
    }

    &__img {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        &:before {
            content: "";
            display: block;
            position: relative;
            padding-bottom: 50.7%;
        }
        
        img {
            display: none;
        }

        &--margin {
            margin: 40px 50px;

            @media all and (max-width: 574px) {
                margin: 24px;
            }
        }

        &--tall {
            background-size: cover;

            &:before {
                padding-bottom: 70%;
            }
        }

        &--rounded {
            border-radius: 40px;
            border: 1px solid #E5E5E5;

            &-large {
                border-radius: 60px;

                @media all and (max-width: 991px) {
                    border-radius: 30px;
                }
            }
        }

        &--cover {
            background-size: cover;
            background-position: center;
        }

        &--photo {
            position: relative;
            background-size: cover;
            overflow: hidden;

            &:before {
                padding-bottom: 121.25%;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background: $colorSlate;
                opacity: 0.8;
                transition: background-color 0.3s ease;
            }
        }
    }

    &__content {
        padding: 24px 48px 48px;
        height: 100%;
        display: flex;
        flex-direction: column;

        @media all and (max-width: 574px) {
            padding: 24px;
        }

        &--padding {
            &-vertical {
                padding: 24px 0;
            }

            &-sm {
                padding: 24px;
            }
        }
    }

    &__headline {
        transition: color 0.3s ease, opacity 0.3s ease;
    }

    &__btn {
        margin-top: auto;
        align-self: flex-start;
    }

    &--padding {

        &-lg {
            padding: 50px 100px;

            @media all and (max-width: 574px) {
                padding: 24px;
            }
        }
    }

    &--align-content-center {
        justify-content: center;
    }

    &__label {
        margin-bottom: 10px;
        font-weight: bold;
    }

    &--link {
        padding: 48px;
        transition: background-color 0.3s ease;

        @media all and (max-width: 574px) {
            padding: 24px;
        }

        &:hover {
            background-color: $colorOffWhite;
        }

        & > * {
            color: $colorBlack;
        }

        p {
            margin: 10px 0 0;
            font-size: 14px;
        }
    }

    &--rounded-link {
        padding: 18px;
        border-radius: 60px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: $colorOffWhite;
        }
    }

    &--person {

        &.is-active {

            .card__img--photo {
                &:after {
                    background-color: transparent;
                }
            }
        }

        &:not(.is-active) {

            .card__content {
                opacity: 0;
                transition: opacity 0.3s ease;
            }
        }

        p {
            margin-top: 12px;
        }

        .card__content {
            padding: 36px 0 0;
            height: auto;
        }
    }

    &--logo {
        padding: 35px 55px;
        height: 135px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #E5E5E5;
        }

        @media all and (min-width: 1351px) {
            height: 167px;
        }

        @media all and (min-height: 575px) and (max-width: 1350px) {
            padding: 25px 35px;
        }

        @media all and (max-width: 574px) {
            padding: 18px 35px;
            height: 120px;
            border-radius: 30px;
        }
    }

    &--fixed-height {
        height: 310px;

        @media all and (min-width: 769px) and (max-width: 1200px) {
            padding: {
                left: 50px;
                right: 50px;
            };
            height: 220px;
        }

        @media all and (max-width: 768px) {
            height: 220px;
            padding: 36px;
        }
    }

    &--white-bg {
        background-color: $colorWhite;
        padding: 0;
        border-radius: 0;

        &:hover {
            background-color: $colorWhite;
        }
    }


    @at-root {
        a#{&} {
            color: inherit;

            &.card--dark-hover {
                transition: background-color 0.3s;

                &:hover {
                    background-color: rgba($color: #000000, $alpha: 0.03);
                }
            }

            &.card--headline-hover {
                &:hover {
                    .card__headline {
                        color: $colorPink;
                        opacity: 1;
                    }
                }
            }
        }
    }
    
}

a.card {
    color: inherit;
}

.cards-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    @media all and (min-width: 991px) {
        grid-template-columns: 1fr 1fr;
    }

    &__col {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__cards {
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;
        padding: 24px 12px;
        background-color: $colorWhite;
        border-radius: 0;
        overflow: hidden;

        @media all and (min-width: 991px) {
            gap: 36px;
            padding: 36px 24px;
        }
    }
}