
.filter {
    display: block;

    &__search {
        display: flex;
        align-items: stretch;
        flex-flow: row nowrap;
        height: 100%;
        width: 100%;
        border-radius: 35px;
        border: 1px solid $colorLightGrey;

        input {
            width: 100%;
            padding: 16px 24px 16px 24px;
            border-radius: 35px 0 0 35px;
            border: 0;
            font: {
                size: rem(20px);
                weight: 700;
            }

            &:focus {
                outline: none;
            }
        }

        button {
            padding: 0;
            border-radius: 0 35px 35px 0;
            background-color: #fff;
            cursor: pointer;

            &:hover {
                svg {
                    opacity: 0.7;
                }
            }

            svg {
                margin: {
                    left: 16px;
                    right: 16px;
                }
                transition: opacity 0.3s;
            }
        }
    }
}
