 
.sidebar {
    will-change: min-height;

    &__inner {
        transform: translate(0, 0); /* For browsers don't support translate3d. */
        transform: translate3d(0, 0, 0);
        will-change: position, transform;
        transition: margin-top .5s;
    }

    &__list {
        a {
            text-decoration: underline;
            font-weight: bold;
            color: $colorSlate;
            transition: color 0.3s;

            &.active {
                color: $colorPink;
            }
        }
    }
}
