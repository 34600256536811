.details {
    display: flex;
    margin: 0.4em 0;

    dt {
        font-weight: 600;
        min-width: 75px;
    }

    dd {
        margin-left: 1em;
    }

    &-wrapper {
        @media (min-width: 768px) {
            display: flex;
            justify-content: space-between;
        }

        .details:not(:last-child) {
            margin-right: 50px;
        }
    }
}