/**
*
* Variables
*
**/

$columnsWidthSet: (10, 20, 25, 30, 33, 40, 50, 60, 67, 70, 75, 80, 90);
$gapSizes: (8, 12, 24, 36, 48, 60, 96, 144);

/* Colors */


$colorPink: #E72176;
$colorTeal: #00BCB5;
$colorSlate: #253746;
$colorBlack: #000000;
$colorDarkTeal: #007F98;
$colorPurple: #6C4796;
$colorGreen: #71BB78;
$colorBlue: #5BC5F2;
$colorWhite: #FFFFFF;
$colorOffWhite: #F4F4F5;
$colorGrey: #98A4AF;
$colorLightGrey: #E2E3E4;

$colorMain: $colorBlack;
$colorTransparentBlack: rgba(#000, 0.1);


$colorRed: #d70000;
$contentLinksDefaultColor: #d70000;

$socialMediaColors: (
    "twitter": #4099ff, 
    "linkedin": #007bb6, 
    "facebook": #3b5998, 
    "googleplus": #dd4b39, 
    "email": #3bb55e, 
    "instagram": #e1306c
);

$colors: (
    "pink": #E72176,
    "teal": #00BCB5,
    "slate": #253746,
    "black": #000000,
    "darkTeal": #007F98,
    "purple": #6C4796,
    "green": #71BB78,
    "blue": #5BC5F2,
    "white": #FFFFFF,
    "offWhite": #F4F4F5,
    "grey": #98A4AF,
    "lightGrey": #E2E3E4,
    "transparentBlack": rgba(#000, 0.1)
);

$bgColorsWhiteFont: (
    "pink": #E72176,
    "teal": #00BCB5,
    "slate": #253746,
    "black": #000000,
    "darkTeal": #007F98,
    "purple": #6C4796,
    "green": #71BB78,
    "blue": #5BC5F2,
    "grey": #98A4AF
);

$FontSizeDefault: 18px;
$FontSizeLarge: 24px;
$FontSizeSmall: 16px;
$FontSizeTiny: 14px;