/*
 * Boilerplate for traditional websites
 *
 * Multi-screen layout styles for your page. Brought to you by the
 * Studio Sidekicks team.
 *
 */
@import '_h5bp';

/*
 * Visual Style Guide styles
 * Remove if you prefer to use a CSS library, like Bootstrap
 */
@import 'components/components';

@view-transition {
    navigation: auto;
}