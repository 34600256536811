

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}



@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, -100vh, 0);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100vh, 0);
    visibility: hidden;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
  animation-duration: 0.75s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}


.animation-bounceInLeft,
.animation-bounceInRight,

.animation-fadeInUp {
    opacity: 0;
}

.animation-phrase {

  &__item {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    // transition: opacity 0.5s linear;
    transition: opacity 0.2s linear, visibility 0.2s linear;

    &.active {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.2s 0.2s linear, visibility 0.2s 0.2s linear;
    }
  }

  p {

    &:not(:last-child) {

      @media all and (max-width: 574px) {
        font-size: 32px;
      }
    }
  }

  &-wrapper {
    position: relative;
    max-height: 900px;
    transition: all 1s ease;

    &:before {
        content: "";
        display: block;
        padding-top: 46.88%;

        @media all and (max-width: 640px) {
          padding-top: 70%;
        }
    }

    &.no-active {
      transform: translateY(100%);
      opacity: 0;
    }
  }
}

@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 0) scale(0);
    transform: rotate3d(0, 0, 1, 0) scale(0);
    opacity: 0;
  }

  10% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -360deg) scale(0);
    transform: rotate3d(0, 0, 1, -360deg) cale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, -720deg) scale(1);
    transform: translate3d(0, 0, -720deg)  scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.5s;

}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 0.5s;
}
