.bg {
    @each $colorName, $color in $colors {
        &-#{$colorName} {
            background-color: $color;
            color: $colorMain;

            @if map-has-key($bgColorsWhiteFont, $colorName) {
                color: $colorWhite;
            }

            .btn--white {
                &:hover {
                    @if map-has-key($bgColorsWhiteFont, $colorName) {
                        color: $colorWhite;
                    }
                }
            }

            .btn--transparent {
                @if map-has-key($bgColorsWhiteFont, $colorName) {
                    color: $colorWhite;
                }
            }

            .btn--offWhite {
                @if map-has-key($bgColorsWhiteFont, $colorName) {
                    color: $colorWhite;
                    // border-color: rgba($colorWhite, 0.05);
                    box-shadow: 0 0 0px 2px rgba($colorWhite, 0.05) inset;

                    &:before,
                    &:after {
                        // top: 2px;
                        // left: 2px;
                        width: calc(100% - 4px);
                        height: calc(100% - 4px);
                        margin: 2px;
                        background-color: rgba($colorWhite, 0.05);
                    }
                }
            }

            .breadcrumbs {
                a,
                li:not(:last-child):after {
                    @if map-has-key($bgColorsWhiteFont, $colorName) {
                        color: $colorWhite;
                    }
                }
            }
            
            .form {
                &__input,
                &__textarea {
                    @if map-has-key($bgColorsWhiteFont, $colorName) {
                        color: $colorWhite;
                    }

                    &:hover {
                        @if map-has-key($bgColorsWhiteFont, $colorName) {
                            border-color: $colorWhite;
                        }
                    }

                    &:focus,
                    &.filled {
                        @if map-has-key($bgColorsWhiteFont, $colorName) {
                            border-color: $colorTeal;
                        }

                        /* active state */
                        ~ .form__label {
                            @if map-has-key($bgColorsWhiteFont, $colorName) {
                                color: $colorTeal;
                            }
                        }
                    }
                }
            }

            /*
            .checkbox {

                &__label {
                    @if map-has-key($bgColorsWhiteFont, $colorName) {
                        color: $colorWhite;
                    } @else {
                        color: $colorSlate;
                    }

                    &:before {
                        @if map-has-key($bgColorsWhiteFont, $colorName) {
                            background-color: rgba($colorBlack, 0.2);
                        }
                    }
                }

                &:hover {
                    + .checkbox__label {
                        &:before {
                            @if map-has-key($bgColorsWhiteFont, $colorName) {
                                background-color: rgba($colorBlack, 0.4);
                            }
                        }
                    }
                }

                &:checked {
                    & + .checkbox__label {

                        &:before,
                        &:hover:before {
                            @if map-has-key($bgColorsWhiteFont, $colorName) {
                                border-color: $colorTeal;
                                background-color: $colorTeal;
                            }
                        }

                        &:after {
                            @if map-has-key($bgColorsWhiteFont, $colorName) {
                                background-color: $colorTeal;
                            }
                        }
                    }
                }
            }

            .select {
                &:hover {
                    @if map-has-key($bgColorsWhiteFont, $colorName) {
                        border-color: $colorWhite;
                    }
                }

                &:focus {
                    @if map-has-key($bgColorsWhiteFont, $colorName) {
                        color: $colorWhite;
                        border-color: $colorTeal;
                        background-color: $color;
                    }
                }

                &.filled {
                    @if map-has-key($bgColorsWhiteFont, $colorName) {
                        color: $colorWhite;
                        border-color: $colorTeal;
                    }

                    &:focus {
                        @if map-has-key($bgColorsWhiteFont, $colorName) {
                            color: $colorWhite;
                            border-color: $colorTeal;
                        }
                    }
                }
            }

            // &.l-header {
            //     .product-logo {
            //         @if map-has-key($bgColorsWhiteFont, $colorName) {
            //             filter: brightness(0) invert(1);
            //         }
            //     }
            // }
            */


            .l-navbar__sub-menu-headline {
                color: $colorTeal;


                @if map-has-key($bgColorsWhiteFont, $colorName) {
                    color: $colorWhite;
                }
            }

            &--tablet {
                @media all and (min-width: 992px) {
                    background-color: transparent;
                }
            }

            // @if map-has-key($bgColorsWhiteFont, $colorName) {
            //     .content ul li:before,
            //     &.content ul li:before {
            //         background-image: url(../images/list-white-arrow.svg);
            //     }
            // }

            .bg-white {
                .checkbox__label {
                    color: $colorSlate;
                }
            }
        }
    }

    &-img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        border-radius: 30px;
        overflow: hidden;

        &-wrapper {
            position: relative;

            &:before {
                content: "";
                display: block;
                padding-top: 40%;

                @media all and (max-width: 991px) {
                    padding-top: 56.25%;
                }
            }
        }

        img {
            display: none;
        }
    }

    &-video {
        position: relative;
        border-radius: 60px;
        overflow: hidden;

        @media all and (max-width: 991px) {
            border-radius: 30px;
        }

        &:before {
            content: "";
            display: block;
            padding-top: 56.25%;
        }

        &:after {
            content: "";
            width: 100%;
            height: 100%;
            pointer-events: none;
        }



        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
