@mixin tab-left-line {

    &:before {
        content: "";
        position: absolute;
        display: block;
        width: 4px;
        height: 36px;
        border-radius: 2px;
        bottom: 50%;
        margin-bottom: -18px;
        left: 0;
        background-color: $colorLightGrey;
    }

    
    &:after {
        content: "";
        position: absolute;
        display: block;
        width: 4px;
        height: 0;
        border-radius: 2px;
        bottom: 50%;
        margin-bottom: -18px;
        left: 0;
        background-color: $colorLightGrey;
        transition: height  0.3s ease-in-out;
    }
}

.tab {
    position: absolute;
    bottom: 0;
    opacity: 0;
    height: 0;
    transition: opacity 1s ease-in-out;

    @media all and (min-width: 992px) {
        position: absolute;
        top: 0;
    }


    &.active {
        position: relative;
        opacity: 1;
        height: auto;
        z-index: 2;
    }

    &-menu {
        padding-top: 50px;

        @media all and (max-width: 991px) {
            display: none;
        }

        &-wrapper {
            padding: 48px 60px;
            height: 100%;
            background-color: $colorWhite;
            border-top-left-radius: 60px;
            border-top-right-radius: 60px;

            @media all and (min-width: 992px) and (max-width: 1200px) {
                padding: 48px;
            }

            @media all and (max-width: 991px) {
                padding: 28px 24px 22px 24px;
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
            }

            &__headline {
                max-width: 330px;
            }
        }

        &__item {
            @include tab-left-line;
            position: relative;
            margin: 5px 0;
            padding-left: 28px;
            z-index: 2;
            cursor: pointer;

            &:hover {

                &:after {
                    height: 36px;
                    background-color: $colorGrey;
                }

                .tab-menu__link{
                    color: $colorGrey;
                }
            }

            &.active {

                &:before {
                    background-color: $colorPink;
                }

                .tab-menu__link {
                    font-weight: 700;
                }
            }
        }

        &__link {
            padding: 8px 0;
            background-color: transparent;
            text-align: left;
            transition: color 0.3s ease;
            cursor: pointer;
            outline: none;
        }
    }

    &-button {
        @include tab-left-line;
        margin-bottom: 22px;
        position: relative;
        padding: 8px 0 8px 28px ;
        width: 100%;
        text-align: left;
        background-color: transparent;
        outline: none;
        transition: color 0.3s ease;
        background-color: $colorWhite;

        @media all and (min-width: 992px) {
            display: none;
        }


        &:hover {

            @media (hover: hover) {
                color: $colorGrey;
            }

            &:after {
                height: 36px;
                background-color: $colorGrey;
            }
        }

        &.active{
            @include tab-left-line;
            font-weight: 700;

            @media all and (min-width: 992px) {
                display: none;
            }

            &:before {
                background-color: $colorPink;
            }
        }
    }

    &__image {

        @media all and (min-width: 641px) {
            height: 350px;
        }

        @media all and (max-width: 640px) {
            width: 250px;
        }

        &--lottie {

            &+.tab__content {
                padding-top: 0;
            }
        }

        img {

            @media all and (min-width: 641px) {
                height: 100%;
                width: auto!important;
            }
        }

        .lottie {
            width: 350px;
            height: 350px;

            @media all and (min-width: 641px) {
                margin: {
                    right: auto;
                    left: 0;
                };
            }

            @media all and (max-width: 640px) {
                width: 250px;
                height: 250px;
            }
        }
    }

    &__content {
        padding-top: 50px;

        @media all and (max-width: 991px) {
            position: relative;
            padding-bottom: 30px;
            margin-bottom: 30px;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            left: -10vw;
            bottom: 0;
            width: 100vw;
            height: 1px;
            background-color: #F9F9FA; 

            @media all and (min-width: 992px) {
                display: none;
            }
        }
    }

    &__headline {

        @media all and (min-width: 576px) {
            max-width: 80%;
        }
    }

    &-container {
        position: relative;
        overflow: hidden;

        @media all and (max-width: 991px) {
            background-color: $colorWhite;
            padding: {
                left: 24px;
                right: 24px;
            }
        }
    }
}

