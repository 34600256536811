/**
*
* Top navigation
*
**/

.l-navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    padding: 0;
    width: 100%;
    background: $colorWhite;
    transition: transform 0.5s, top 0.4s ease-out;

    @media screen and (max-width: 1024px) {
        padding: 24px;
        transition: transform 0.5s, top 0.4s ease-out, background-color 0.3s 0.5s;
    }

    + #page-wrapper,
    + #barba-wrapper {
        margin-top: 100px;

        @media screen and (max-width: 1024px) {
            margin-top: 76px;
        }
    }

     + #page-wrapper:has(.breadcrumbs--wide),
     + #barba-wrapper:has(.breadcrumbs--wide) {
        margin-top: 126px;

        @media screen and (min-width: 1024px) {
            margin-top: 180px;
        }
    }

    .nav-open & {
        @media screen and (max-width: 1024px) {
            background-color: $colorSlate;
            transition: transform 0.5s, top 0.4s ease-out, background-color 0.3s 0.0s;
        }
    }

    &--loading {
        transform: translateY(-101%);
    }

    &--scrolled {
        transform: translateY(-101%);
    }

    &__container {
        position: static !important;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 1024px) {
            width: 100% !important;
        }

        &.container {
            @media screen and (max-width: 991px) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &__logo {
        height: 30px;
        flex-shrink: 0;

        @media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
            width: 222px;
        }

        @media all and (max-width: 580px) {
            height: auto;
            width: 80%;
        }

        &-link {
            display: block;
            margin-right: 72px;

            @media screen and (min-width: 1025px) and (max-width: 1100px) {
                margin-right: 36px;
            }
        }
    }

    &__logo-with-nav {
        @media screen and (min-width: 1025px) {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            width: 100%;
        }
    }

    &__compound-logo {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        color: $colorPink;
        font: {
            size: 20px;
            weight: 700;
        }

        span {
            padding-left: 12px;
        }

        @media screen and (max-width: 1024px) {
            font-size: 36px;
            color: $colorWhite;
        }

        svg {
            &:first-child {
                @media screen and (max-width: 1024px) {
                    width: 44px;
                    height: 44px;
                }
            }

            &:last-child {
                @media screen and (max-width: 1024px) {
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }

    &__menu {
        &-wrapper {
            display: none;
            flex-direction: row;
            width: 100%;

            @media screen and (max-width: 1024px) {
                position: absolute;
                top: 80px;
                left: 0;
                width: 100%;
                max-height: calc(100vh - 80px);
                height: calc(100vh - 80px);
                overflow: auto !important;
                background-color: $colorSlate;

                @supports (-webkit-overflow-scrolling: touch) {
                    max-height: calc((var(--vh, 1vh) * 100) - 72px);
                    height: calc((var(--vh, 1vh) * 100) - 72px);
                }
            }

            @media screen and (min-width: 1025px) {
                display: flex !important;
                align-items: center;
            }
        }
    }

    &__main-menu,
    &__extra-menu {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        width: 100%;

        @media screen and (max-width: 1024px) {
            display: block;
            padding: 0;
            min-height: calc(100vh - 72px);

            @supports (-webkit-overflow-scrolling: touch) {
                min-height: calc((var(--vh, 1vh) * 100) - 72px);
            }
        }
    }

    &__main-menu {
        @media screen and (max-width: 1024px) {
            flex-direction: column;
            display: flex;
        }

        &-item {
            // position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            transition: background-color 0.3s;

            @media screen and (max-width: 1024px) {
                display: block;
                height: auto;
                padding: 0 24px 0 24px;
            }

            &:last-child {
                .l-navbar__main-menu-link {
                    padding-right: 0;
                }
            }

            &--static {
                position: static;
            }

            &--simple-dropdown {
                position: relative;

                // .l-navbar__main-menu-link {
                //     @media screen and (max-width: 1024px) {
                //         padding-bottom: 0 !important;
                //     }
                // }
            }

            &--last {
                flex: 1 1 100%;

                @media screen and (max-width: 1024px) {
                    display: flex;
                    padding: 0;
                }

                .l-navbar__compound-logo {
                    margin: 0 0 0 auto;
                    padding: 39px 0;

                    @media screen and (max-width: 1024px) {
                        display: block;
                        margin: auto auto 0 auto;
                        padding: 24px 0;
                        width: 100%;
                        text-align: center;
                        background-color: $colorTransparentBlack;
                    }
                }
            }

            svg {
                transition: transform 0.3s;
            }

            &.open {
                @media screen and (max-width: 1024px) {
                    background-color: $colorTransparentBlack;
                }

                .l-navbar__main-menu-link {
                    color: $colorBlack;
                    background-color: #fff;

                    @media screen and (max-width: 1024px) {
                        background-color: transparent;
                        color: $colorWhite;
                    }

                    &:hover {
                        @media screen and (min-width: 1025px) {
                            opacity: 1;
                            color: $colorBlack;
                        }
                    }

                    &:active,
                    &:focus {
                        opacity: 1;
                        color: $colorBlack;

                        @media screen and (max-width: 1024px) {
                            color: $colorWhite;
                        }
                    }
                }

                svg {
                    transform: rotate(180deg);
                }

                // &.l-navbar__main-menu-item--simple-dropdown {
                //     .l-navbar__main-menu-link {
                //         @media screen and (min-width: 1025px) {
                //             background-color: #6B032D;//$colorRed;
                //             color: #fff;
                //         }

                //         @media screen and (max-width: 1024px) {
                //             padding-bottom: 0;
                //         }
                //     }
                // }
            }

            &:last-child {
                .l-navbar__sub-menu {
                    left: auto;
                    right: 0;
                }
            }

            .l-navbar__sub-menu {
                @media screen and (max-width: 1024px) {
                    position: static;
                    display: none;
                }
            }
        }

        &-link {
            display: block;
            padding: 40px 24px;
            background-color: transparent;
            font: {
                size: 20px;
                weight: 700;
            }
            color: $colorSlate;
            text-align: left;
            white-space: nowrap;
            line-height: 1.1;
            cursor: pointer;
            transition: background-color 0.3s, color 0.3s, opacity 0.3s;

            @media screen and (min-width: 1025px) and (max-width: 1100px) {
                padding: 40px 16px;
            }

            @media screen and (max-width: 1024px) {
                display: inline-block;
                padding: 12px 0;
                font-size: 36px;
                color: $colorWhite;
            }

            &:focus {
                outline: 0;
            }

            &:hover {
                @media screen and (min-width: 1025px) {
                    color: $colorBlack;
                }
            }

            &:focus,
            &:active {
                @media screen and (min-width: 1025px) {
                    opacity: 0.6;
                }
            }

            &.dropdown {
                padding: 40px 24px;

                @media screen and (max-width: 1024px) {
                    padding: 18px 0;
                }

                > svg {
                    pointer-events: none;

                    @media screen and (max-width: 1024px) {
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            &--decorate {
                font-weight: bold;
                color: $colorPink;
            }
        }
    }

    &__sub-menu {
        position: absolute;
        top: calc(100% - 16px);
        // left: 0;
        z-index: -1;
        // padding: 24px;
        // min-width: 1246px;
        width: 100%;

        // left: 50%;
        // transform: translateX(-50%);
        left: 0;

        // background-color: $colorWhite;

        opacity: 0;
        visibility: hidden;

        transition: opacity 0.5s, visibility 0.5s;

        @media screen and (max-width: 1024px) {
            position: static;
            transform: none;
            min-width: 1px;
            width: 100%;
            // padding: 0 0 24px 0;
            // background-color: $colorSlate;
        }

        > div {
            background-color: $colorWhite;
            border: 1px solid $colorLightGrey;

            @media screen and (max-width: 1024px) {
                border: 0;
                width: 100% !important;
                background-color: transparent;
            }
        }

        .open & {
            z-index: 1;
            opacity: 1;
            visibility: visible;

            @media screen and (min-width: 1025px) {
                display: block !important;
            }

            @media screen and (max-width: 1024px) {
                position: static;
            }
        }

        .l-navbar__top-menu-item.open & {
            @media screen and (max-width: 1024px) {
                position: absolute;
            }
        }

        .l-navbar__main-menu-item--static & {
            width: 100%;
            padding: 0;

            .l-navbar__sub-menu-item {
                display: flex;

                @media screen and (max-width: 1024px) {
                    width: 33%;
                }
            }

            .l-navbar__sub-menu-link {
                padding: 20px 10px;
                white-space: normal;

                @media screen and (max-width: 1024px) {
                    padding: 20px 10px;

                    margin: {
                        left: auto;
                        right: auto;
                    };
                }
            }
        }

        &--columns {

            .l-navbar__sub-menu-column {
                display: flex;
                flex-flow: row wrap;
                align-items: stretch;

                .l-navbar__sub-menu-item {
                    // width: 50%;
                    flex: 1 0 25%;
                }

                @media screen and (max-width: 1024px) {
                    display: block;
                }
            }
        }

        &--simple {
            min-width: 1px;
            width: auto;
            left: 0;

            transform: none;
            border: 1px solid $colorLightGrey;
            background-color: $colorWhite;

            @media screen and (min-width: 1025px) {
                padding: 16px 0;
            }

            @media screen and (max-width: 1024px) {
                background-color: transparent;
                border: 0;
                // padding: 12px 0;
                padding: 0;
            }

            .l-navbar__sub-menu-link {
                height: auto;
                padding: 8px 36px;
                border: 0;
                color: $colorBlack;
                white-space: nowrap;
                transition: opacity 0.3s;

                @media screen and (max-width: 1024px) {
                    padding: 12px 0;
                    color: $colorWhite;
                    white-space: normal;
                }

                &:hover {
                    @media screen and (min-width: 1025px) {
                        opacity: 0.7;
                    }
                }
            }
        }

        &-item {
            padding: 36px 48px;

            @media screen and (max-width: 1024px) {
                margin-bottom: 12px;
                padding: 12px 0 12px 0;
                background-color: transparent !important;
                // background-color: $colorTransparentBlack !important;
                border-bottom: 2px solid rgba($colorWhite, 0.1);
            }

            &:last-child {
                // margin-bottom: 0;
                // padding-bottom: 0;
                border: 0;
            }
        }


        &-link {
            display: block;
            padding: 8px 0;
            color: inherit;
            font: {
                size: 18px;
                weight: 300;
            }
            transition: opacity 0.3s;

            @media screen and (max-width: 1024px) {
                padding: 12px 0;
                color: $colorWhite;
            }

            &:hover {
                @media screen and (min-width: 1025px) {
                    opacity: 0.7;
                }
            }
        }

        &-headline {
            margin: 0;
            padding: 0 0 8px 0;
            font: {
                size: 24px;
                weight: 700;
            }

            @media screen and (max-width: 1024px) {
                color: $colorTeal !important;
            }
        }



        .l-navbar__menu-item.open & {
            @media screen and (min-width: 1025px) {
                transform: translateY(100%);
            }
        }
    }
}
