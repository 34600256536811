/**
*
* Content (text stylisation connected to CMS WYSIWYG editor)
*
**/
.content {
    p,
    li,
    td {
        font-size: $FontSizeDefault;
        line-height: 1.35;
        font-weight: 300;

        @media screen and (max-width: 767px) {
            font-size: $FontSizeSmall;
        }
    }

    .content__small-text,
    .content__small-text p,
    .content__small-text li,
    .content__small-text td,
    &--small-text p,
    &--small-text li,
    &--small-text td {
        font-size: $FontSizeSmall;

        @media screen and (max-width: 767px) {
            font-size: $FontSizeTiny;
        }
    }

    .content__large-text,
    .content__large-text p,
    .content__large-text li,
    .content__large-text td,
    &--large-text p,
    &--large-text li,
    &--large-text td {
        font-size: $FontSizeLarge;

        @media screen and (max-width: 767px) {
            font-size: $FontSizeDefault;
        }
    }

    p:not(:last-child) {
        // margin: 0;
        // padding: .9em 0;

        padding: 0;
        margin: 20px 0;
    }

    ul:not(:last-child),
    ol:not(:last-child) {
        padding-bottom: 1em;
    }

    p {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    /* uls, ols */
    ul {
        @extend .list-ul;
    }

    ol {
        @extend .list-ol;
    }

    blockquote {
        @extend .blockquote;
    }

    img:not([src*=".svg"]) {
        height: auto;
    }

    img.lazy,
    .bg-video {
        margin: 48px 0;
        width: 100%;
        border-radius: 60px;

        @media all and (max-width: 991px) {
            border-radius: 30px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0.75em 0 0.5em 0;

        &:first-child {
            margin: 0 0 0.5em 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    a.btn {
        margin-top: 12px;

        &.btn--large {
            margin-top: 32px;
        }
    }
}
