

.listing-item {
    display: block;

    &--link {
        margin-bottom: 48px;

        &:hover {
            .listing-item__img {
                border-radius: 120px;
            }

            .listing-item__text {
                opacity: 0.7;
            }
        }
    }

    &__img {
        transition: border-radius 0.3s;
    }

    &__text {
        transition: opacity 0.3s;

        .info {
            @media all and (max-width: 991px) {
                margin-bottom: 0;
            }
        }
    }
}
