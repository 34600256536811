/**
*
* Headlines
*
**/

%commonStyles {
    margin: 0;
    line-height: 1.25;
    font-weight: 300;
}

.headline {
    &-0 {
        @extend %commonStyles;
        @include dynamicFontSize(42, 100, 480, 1700);
        line-height: 1;
    }

    &-1 {
        @extend %commonStyles;
        @include dynamicFontSize(48, 80, 1200, 1600);
    }

    &-2 {
        @extend %commonStyles;
        @include dynamicFontSize(36, 48, 1200, 1600);
    }

    &-3 {
        @extend %commonStyles;
        @include dynamicFontSize(24, 36, 1200, 1600);
    }

    &-4 {
        @extend %commonStyles;
        @include dynamicFontSize(20, 24, 1200, 1600);
    }

    &-5 {
        @extend %commonStyles;
        @include dynamicFontSize(18, 20, 1200, 1600);
    }

    &-6 {
        @extend %commonStyles;
        @include dynamicFontSize(40, 63, 1200, 1600);
    }

    &-7 {
        @extend %commonStyles;
        @include dynamicFontSize(108, 216, 1200, 1600);
    }

    &-bold {
        font-weight: 700;
    }

    &-with-cta {
        margin-bottom: 1.5em; 
        
        @media (min-width: 568px) {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__headline {
                margin-bottom: 0;
            }
        }

        &__controls {
            margin: 0;
            font-size: 16px;

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.preheadline {
    margin: 0;
    line-height: 1.25;
    // text-transform: lowercase;
    font-weight: 700;
    font-size: 18px;
}