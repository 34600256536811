
$gradientHeight: (
    10: 10%,
    20: 20%,
    30: 30%,
    40: 40%,
    50: 50%,
    60: 60%,
    65: 65%,
    70: 70%,
    80: 80%,
    90: 90%,
);

.gradient {
    position: relative;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    &--from {

        &-middle {
            &-to-bottom {
                background: linear-gradient(to bottom, #FFFFFF 50%, $colorOffWhite 50%);
            }
        }

        &-top {

            &-to {
                &-bottom {
                    background: #fff linear-gradient(180deg, $colorOffWhite,hsla(0,0%,100%,0) 100%) 0 0 no-repeat padding-box;
                }
            }
        }
    }

    &--top {
        @each $colorName, $color in $colors {

            &-#{$colorName} {
                background: linear-gradient(to bottom, $color 50%, transparent 50%);

                @each $heightValue, $value in $gradientHeight {

                    &.gradient--top-#{$heightValue} {
                        background: linear-gradient(to bottom, $color $value, transparent $value);

                        @media all and (max-width: 640px) {
                            background: linear-gradient(to bottom, $color 80%, transparent 80%);
                        }
                    }
                }
            }
        }
    }

    &--bottom {
        @each $colorName, $color in $colors {

            &-#{$colorName} {
                &:after {
                    content: "";
                    background: linear-gradient(to bottom, transparent 50%, $color 50%);
                }

                @each $heightValue, $value in $gradientHeight {
                    &.gradient--bottom-#{$heightValue} {

                        &:after {
                            background: linear-gradient(to top, $color $value, transparent $value);

                            @media all and (max-width: 640px) {
                                background: linear-gradient(to top, $color 80%, transparent 80%);
                            }
                        }
                    }
                }
            }
        }
    }
}
