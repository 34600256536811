.image-and-text {

    &__text {

        &--left {
            padding-left: 17.5vw;
            padding-right: 6.6vw;
    
            @media all and (min-width: 992px) and (max-width: 1280px) {
                padding-left: 13.2vw;
            }
    
            @media all and (max-width: 991px) {
                padding-left: 6.6vw;
            }
        }

        &--right {
            padding-right: 17.5vw;
            padding-left: 6.6vw;
    
            @media all and (min-width: 992px) and (max-width: 1280px) {
                padding-right: 13.2vw;
            }
    
            @media all and (max-width: 991px) {
                padding-right: 6.6vw;
            }
        }
    }

    &__image {
        position: absolute; 
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;

        &--left {

            @media all and (min-width: 992px) {
                right: 24px;
            }
        }

        &--right {

            @media all and (min-width: 992px) {
                left: 24px;
            }
        }

        &-wrapper {

            @media all and (max-width: 991px) {
                margin-bottom: 24px;
            }

            &:before {
                content: "";
                display: block;
                padding-top: 61.18%;   
            }

            &--lg {

                &:before {
                    padding-top: 75%;   
                }
            }
        }

        img {
            display: none;
        }

        &--top {
            background-position: center top;
        }
    }
}