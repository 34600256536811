.icon {
    margin-bottom: 24px;
    width: 144px;
    height: 144px;
    border-radius: 30px;

    &-box {
        position: relative;
        margin: 0 auto 2em;
        padding-top: 80%;
        width: 80%;

        @media all and (max-width: 991px) {
            margin-bottom: 1em;
            padding-top: 35%;
            width: 35%;
        }

        svg {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
        }
    }

    &-in-phrase {

        svg {
            width: 114px;
            height: 101px;
    
            @media all and (max-width: 768px) {
                width: 80px;
                height: 70.9px;
            }
        }
    }
}