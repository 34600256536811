.address-list {
    margin-top: -65px;
    border-radius: 40px;
    overflow: hidden;

    @media all and (min-width: 768px) {
        margin-top: -125px;
        display: flex;
    }

    @media all and (max-width: 991px) {
        border-radius: 30px;
    }


    &__item {
        position: relative;
        padding: 48px 48px 48px 116px;

        @media all and (min-width: 768px) {
            flex: 1 1 100%;
        }

        @media all and (min-width: 576px) and (max-width: 991px) {
            padding: 24px 24px 24px 98px;
        }

        @media all and (max-width: 575px) {
            padding: 24px 24px 24px 70px;
        }

        &:first-child {
            background-color: #21313F;
        }

        &:nth-of-type(2) {
            background-color: $colorSlate;
        }

        & > * {
            color: $colorWhite;
        }

        svg {
            position: absolute;
            left: 62px;
            top: 45px;

            @media all and (min-width: 576px) and (max-width: 991px) {
                left: 46px;
                top: 20px;
            }

            @media all and (max-width: 575px) {
                left: 22px;
                top: 20px;
            }
        }

    }

}