/**
*
* Main footer
*
**/

.l-footer {
    padding: 48px 0 96px 0;
    background-color: $colorSlate;

    @media all and (max-width: 767px) {
        padding-bottom: 48px;
    }

    &__wide-logo {
        position: relative;
        margin: 48px auto;
        max-width: 460px;
        width: 100%;
        text-align: center;

        @media all and (max-width: 767px) {
            max-width: 70%;
            margin: 36px auto;
        }

        img {
            display: block;
            min-height: 55px !important;

            @media all and (max-width: 767px) {
                min-height: 7vw !important;
            }
        }
    }

    &__menu {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;

        @media all and (min-width: 768px) and (max-width: 1120px) {
            flex-flow: row wrap;
        }

        @media all and (max-width: 767px) {
            display: block;
        }

        &-item {
            flex: 1;
            color: #fff;

            @media all and (min-width: 768px) and (max-width: 1120px) {
                flex-basis: 50%;
                text-align: center;
                margin-bottom: 24px;
            }

            @media all and (max-width: 767px) {
                text-align: center;
                margin-bottom: 48px;
            }

            &:last-child {
                @media all and (max-width: 767px) {
                    margin-bottom: 0;
                }
            }

            &--align-right {
                text-align: right;

                @media all and (min-width: 200px) and (max-width: 1120px) {
                    text-align: center;
                }
            }

            a {
                &:not([class]) {
                    display: block;
                    margin: 12px 0;
                    text-decoration: underline;
                    color: inherit;
                    transition: opacity 0.3s;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

            &--form {
                flex: 0 0 auto;

                @media all and (min-width: 768px) and (max-width: 1120px) {
                    flex-basis: 50%;
                }
            }

            .one-line-form {
                margin-top: 12px;

                &__input {
                    color: #fff;
                }
            }

            .checkbox__container {
                text-align: left;
            }
        }
    }

    &__address {
        font-style: normal;

        p {
            margin: 12px 0;
        }
    }

    &__logos-wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;

        @media all and (min-width: 200px) and (max-width: 1120px) {
            justify-content: center;
        }

        figure {
            img {
                margin-left: 24px;
                max-height: 84px;
            }

            &:first-child {
                img {
                    margin-left: 0;
                }
            }
        }
    }

    &__small {
        font-size: 14px;
        color: rgba($colorWhite, 0.3);

        &:last-child {
            margin-bottom: 0;
        }

        a:not([class]) {
            display: inline;

            + a {
                padding-left: 12px;
            }
        }
    }

    .container--border-top {
        border-color: rgba($colorWhite, 0.1);
    }
}
