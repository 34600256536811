/**
*
* Breadcrumbs
*
**/

.breadcrumbs {
    clear: both;
    padding: 1em 0;
    color: $colorTeal;
    font-size: $FontSizeDefault;
    font-weight: 700;
    text-transform: lowercase;

    @media (max-width: 568px) {
        overflow: auto;
        white-space: nowrap;
    }

    
    .bg-teal &,
    .bg-grey & {
        color: $colorPink;
    }

    .bg-pink  & {
        color: #fff;
    }

    .bg-offWhite & {
        color: $colorSlate;
    }

    li {
        display: inline-block;
        text-transform: capitalize;

        &:not(:last-child):after {
            content: '/';
            padding: 0 0 0 6px;
            color: $colorSlate;
        }
    }

    a {
        color: $colorSlate;
        text-decoration: underline;
        transition: color 0.3s;

        &:hover {
            color: $colorPink;
            text-decoration: none;
        }
    }

    a, span {
    }

    &.breadcrumbs--wide {
        padding: 16px 0;
        font-size: 14px;
        position: fixed;
        top: 76px;
        left: 0;
        right: 0;
        z-index: 10;
        transition: transform 0.5s;

        .container {
            max-width: 1680px;
        }

        .page-scrolled & {
            transform: translateY(-126px);
        }

        @media screen and (min-width: 1024px) {
            top: 100px;
            padding: 32px 0;
            font-size: 16px;

            .page-scrolled & {
                transform: translateY(-184px);
            }
        }
        
        li {
            color: $colorWhite;

            &:not(:last-child):after {
                .l-header & {
                    color: $colorPink;
                }
            }
        }
    
        a, span {
            color: $colorWhite;
            text-decoration: none;
            transition: color 0.3s;
    
            &:hover {
                .l-header & {
                    color: $colorPink;
                }
            }
        }

        &.bg-white,
        &.bg-lightGrey {
            li {
                color: $colorSlate;

                &:not(:last-child):after {
                    .l-header & {
                        color: $colorPink;
                    }
                }
            }

            a, span {
                color: $colorSlate;
        
                &:hover {
                    .l-header & {
                        color: $colorPink;
                    }
                }
            }
        }
    }
}