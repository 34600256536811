
.push {
    &-up {
        @each $size in $gapSizes {
            &-#{$size} {
                transform: translateY(($size * (-1)) * 1px);
            }
        }
    }

    &-down {
        @each $size in $gapSizes {
            &-#{$size} {
                transform: translateY($size * 1px);
            }
        }
    }

}